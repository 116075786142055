import React from "react";
import { FiTruck, FiAward, FiHeadphones } from "react-icons/fi";

const FeaturesSection = () => {
  return (
    <div className="bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500 py-16">
      <div className="max-w-7xl mx-auto px-6 lg:px-8 grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
        
        {/* Entrega Grátis */}
        <div className="flex flex-col items-center p-6">
          <FiTruck className="w-16 h-16 mb-4 text-gray-500" />
          <h3 className="text-xl font-semibold text-gray-800">Entrega Grátis</h3>
          <p className="text-gray-500 mt-2">
            Entrega grátis em toda Boa Vista em até 7 dias.
          </p>
        </div>

        {/* Produtos de Qualidade */}
        <div className="flex flex-col items-center p-6">
          <FiAward className="w-16 h-16 mb-4 text-gray-500" />
          <h3 className="text-xl font-semibold text-gray-800">Produtos de Qualidade</h3>
          <p className="text-gray-500 mt-2">
            Selecionamos apenas produtos com o mais alto padrão de qualidade.
          </p>
        </div>

        {/* Suporte ao Cliente */}
        <div className="flex flex-col items-center p-6">
          <FiHeadphones className="w-16 h-16 mb-4 text-gray-500" />
          <h3 className="text-xl font-semibold text-gray-800">Suporte ao Cliente</h3>
          <p className="text-gray-500 mt-2">
            Estamos aqui para ajudar! Suporte rápido e atencioso.
          </p>
        </div>
      </div>
      
      {/* Sobre a Ecos Eyewear */}
      <div className="max-w-4xl mx-auto mt-16 px-6 text-center">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">Sobre a Ecos Eyewear</h2>
        <p className="text-gray-600">
          A Ecos Eyewear é dedicada a oferecer óculos que combinam estilo, conforto e sustentabilidade. Nossa missão é proporcionar produtos de qualidade que respeitam o meio ambiente, garantindo uma experiência única para nossos clientes.
        </p>
      </div>
    </div>
  );
};

export default FeaturesSection;
