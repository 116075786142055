import React, { Fragment, useState, useEffect } from "react";
import { sendVerificationCodeReq, verifyCodeReq } from "./fetchApi";
import { useHistory } from "react-router-dom";
import zxcvbn from "zxcvbn"; // Biblioteca para avaliação da força da senha

const Signup = () => {
  const history = useHistory();
  const [data, setData] = useState({
    name: "",
    phone: "",
    password: "",
    cPassword: "",
    verificationCode: "",
    error: {},
    loading: false,
    success: "",
    codeSent: false,
    verified: false,
    termsAccepted: false,
    privacyAccepted: false,
  });

  const [passwordStrength, setPasswordStrength] = useState(null);

  // Função para exibir alertas
  const alert = (msg, type) => (
    <div className={`text-sm text-${type}-500`}>{msg}</div>
  );

  // Função para lidar com mudanças nos campos de entrada
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData((prev) => ({
      ...prev,
      [name]: value,
      success: "",
      error: { ...prev.error, [name]: "" },
    }));
  };

  // Formatar número de telefone
  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{2})(\d{8,9})$/);
    if (match) {
      return `(${match[1]}) ${match[2]}`;
    }
    return value;
  };

  // Validar número de telefone
  const validatePhoneNumber = (phone) => {
    const phoneRegex = /^\(\d{2}\) \d{8,9}$/;
    return phoneRegex.test(phone);
  };

  // Enviar código de verificação
  const sendCode = async () => {
    if (!data.name || data.name.length < 5 || data.name.length > 45) {
      return setData((prev) => ({
        ...prev,
        error: { name: "O nome deve ter entre 5 e 45 caracteres." },
      }));
    }

    if (!data.phone || !validatePhoneNumber(data.phone)) {
      return setData((prev) => ({
        ...prev,
        error: { phone: "Por favor, insira um número de telefone válido." },
      }));
    }

    const cleanedPhone = data.phone.replace(/[^\d]/g, "");
    const phoneToSend = cleanedPhone.slice(0, 2) + cleanedPhone.slice(3);

    try {
      setData((prev) => ({ ...prev, loading: true }));
      await sendVerificationCodeReq({
        phone: phoneToSend,
        name: data.name,
      });
      setData((prev) => ({
        ...prev,
        codeSent: true,
        success: "Código de verificação enviado para seu telefone.",
        loading: false,
      }));
    } catch (err) {
      setData((prev) => ({
        ...prev,
        error: {
          global: err.message || "Erro ao enviar o código de verificação.",
        },
        loading: false,
      }));
    }
  };

  // Verificar código de verificação
  const verifyCode = async () => {
    if (!data.verificationCode) {
      return setData((prev) => ({
        ...prev,
        error: {
          verificationCode: "Por favor, insira o código de verificação.",
        },
      }));
    }

    if (data.cPassword !== data.password) {
      return setData((prev) => ({
        ...prev,
        error: {
          cPassword: "As senhas não coincidem.",
          password: "As senhas não coincidem.",
        },
      }));
    }

    // Verificar força da senha antes de prosseguir
    if (passwordStrength !== null && passwordStrength < 2) {
      return setData((prev) => ({
        ...prev,
        error: {
          password: "A nova senha é muito fraca. Por favor, escolha uma senha mais forte.",
        },
      }));
    }

    try {
      setData((prev) => ({ ...prev, loading: true }));

      const cleanedPhone = data.phone.replace(/[^\d]/g, "");
      const phoneToSend = cleanedPhone.slice(0, 2) + cleanedPhone.slice(3);

      const response = await verifyCodeReq({
        phone: phoneToSend,
        code: data.verificationCode,
        name: data.name,
        password: data.password,
        cPassword: data.cPassword,
      });

      if (response.success) {
        setData((prev) => ({
          ...prev,
          verified: true,
          success: "Conta criada com sucesso.",
          loading: false,
          verificationCode: "",
          codeSent: false,
        }));

        setTimeout(() => {
          history.push("/login");
        }, 2000);
      } else {
        setData((prev) => ({
          ...prev,
          error: { global: response.error || "Código inválido." },
          loading: false,
        }));
      }
    } catch (err) {
      setData((prev) => ({
        ...prev,
        loading: false,
        error: {
          global: err.message || "Erro ao verificar o código.",
        },
      }));
    }
  };

  // Gerenciar mudanças nos checkboxes de termos e privacidade
  const handleTermsChange = (e) => {
    setData((prev) => ({ ...prev, termsAccepted: e.target.checked }));
  };

  const handlePrivacyChange = (e) => {
    setData((prev) => ({ ...prev, privacyAccepted: e.target.checked }));
  };

  // Função para criar a conta após verificar o código
  const handleCreateAccount = () => {
    const newErrors = {};

    if (!data.name) {
      newErrors.name = "Nome é obrigatório.";
    }
    if (!data.phone) {
      newErrors.phone = "Telefone é obrigatório.";
    }
    if (!data.password) {
      newErrors.password = "Senha é obrigatória.";
    }
    if (!data.cPassword) {
      newErrors.cPassword = "Confirmação de senha é obrigatória.";
    }
    if (!data.termsAccepted) {
      newErrors.terms = "Você deve aceitar os termos.";
    }
    if (!data.privacyAccepted) {
      newErrors.privacy = "Você deve aceitar a política de privacidade.";
    }

    if (Object.keys(newErrors).length > 0) {
      setData((prev) => ({
        ...prev,
        error: { ...prev.error, ...newErrors },
      }));
      return;
    }

    verifyCode();
  };

  // Função para renderizar mensagens de sucesso
  const renderSuccessMessage = () => (
    <div className="text-green-500">
      {data.success} Você será redirecionado para a página de login.
    </div>
  );

  // Função para renderizar a barra de força da senha
  const renderPasswordStrength = () => {
    const strengthLabels = ["Fraca", "Moderada", "Boa", "Forte", "Muito Forte"];
    const strengthColors = [
      "bg-red-500",
      "bg-yellow-500",
      "bg-blue-500",
      "bg-green-500",
      "bg-purple-500",
    ];

    if (passwordStrength === null) return null;

    return (
      <div className="mt-1">
        <div className="flex items-center">
          <div className="w-full bg-gray-300 rounded h-2 mr-2">
            <div
              className={`h-2 rounded ${strengthColors[passwordStrength]}`}
              style={{ width: `${(passwordStrength + 1) * 20}%` }}
            ></div>
          </div>
          <span className="text-sm text-gray-700">
            {strengthLabels[passwordStrength]}
          </span>
        </div>
      </div>
    );
  };

  // Avaliar a força da senha sempre que a nova senha for alterada
  useEffect(() => {
    if (data.password) {
      const evaluation = zxcvbn(data.password);
      setPasswordStrength(evaluation.score);
    } else {
      setPasswordStrength(null);
    }
  }, [data.password]);

  return (
    <Fragment>
      <div className="text-center text-2xl mb-6">
        Seja Bem-Vindo ao EcosEyewaer!
        <br />
        Faça seu Cadastro
      </div>
      {data.verified ? (
        renderSuccessMessage()
      ) : (
        <form className="space-y-4">
          {data.success && alert(data.success, "green")}
          {data.error.global && alert(data.error.global, "red")}

          {/* Campo de Nome Completo */}
          <div className="flex flex-col">
            <label htmlFor="name">
              Nome Completo{" "}
              <span className="text-sm text-gray-600 ml-1">*</span>
            </label>
            <input
              name="name"
              onChange={handleChange}
              value={data.name}
              type="text"
              id="name"
              className={`px-4 py-2 focus:outline-none border ${
                data.error.name ? "border-red-500" : "border-gray-300"
              }`}
              placeholder="Digite seu nome completo"
            />
            {data.error.name && alert(data.error.name, "red")}
          </div>

          {/* Campo de Telefone */}
          <div className="flex flex-col">
            <label htmlFor="phone">
              Telefone <span className="text-sm text-gray-600 ml-1">*</span>
            </label>
            <input
              name="phone"
              onChange={(e) =>
                handleChange({
                  target: {
                    name: "phone",
                    value: formatPhoneNumber(e.target.value),
                  },
                })
              }
              value={data.phone}
              type="text"
              id="phone"
              placeholder="(XX) XXXXX-XXXX"
              className={`px-4 py-2 focus:outline-none border ${
                data.error.phone ? "border-red-500" : "border-gray-300"
              }`}
            />
            {data.error.phone && alert(data.error.phone, "red")}
            <button
              type="button"
              onClick={sendCode}
              disabled={data.loading}
              className="mt-2 bg-gray-800 text-white rounded-full px-6 py-2 transition duration-300 ease-in-out focus:outline-none hover:bg-gray-700"
            >
              {data.loading ? "Enviando..." : "Enviar Código"}
            </button>
            {data.codeSent && (
              <p className="text-green-500 mt-2">
                Código enviado com sucesso para o telefone.
              </p>
            )}
          </div>

          {/* Campos que aparecem após o envio do código */}
          {data.codeSent && (
            <>
              {/* Campo de Código de Verificação */}
              <div className="flex flex-col">
                <label htmlFor="verificationCode">
                  Código de Verificação{" "}
                  <span className="text-sm text-gray-600 ml-1">*</span>
                </label>
                <input
                  name="verificationCode"
                  onChange={handleChange}
                  value={data.verificationCode}
                  type="text"
                  id="verificationCode"
                  className={`px-4 py-2 focus:outline-none border ${
                    data.error.verificationCode
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                  placeholder="Digite o código recebido"
                />
                {data.error.verificationCode &&
                  alert(data.error.verificationCode, "red")}
              </div>

              {/* Campo de Nova Senha */}
              <div className="flex flex-col">
                <label htmlFor="password">
                  Senha <span className="text-sm text-gray-600 ml-1">*</span>
                </label>
                <input
                  name="password"
                  onChange={handleChange}
                  value={data.password}
                  type="password"
                  id="password"
                  className={`px-4 py-2 focus:outline-none border ${
                    data.error.password ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Digite sua senha"
                />
                {data.error.password && alert(data.error.password, "red")}
                {renderPasswordStrength()}
              </div>

              {/* Campo de Confirmação de Senha */}
              <div className="flex flex-col">
                <label htmlFor="cPassword">
                  Confirmação de Senha{" "}
                  <span className="text-sm text-gray-600 ml-1">*</span>
                </label>
                <input
                  name="cPassword"
                  onChange={handleChange}
                  value={data.cPassword}
                  type="password"
                  id="cPassword"
                  className={`px-4 py-2 focus:outline-none border ${
                    data.error.cPassword ? "border-red-500" : "border-gray-300"
                  }`}
                  placeholder="Confirme sua senha"
                />
                {data.error.cPassword && alert(data.error.cPassword, "red")}
              </div>

              {/* Checkbox de Aceitação dos Termos */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={data.termsAccepted}
                  onChange={handleTermsChange}
                  className="mr-2"
                />
                <label>
                  Aceito os{" "}
                  <a href="/terms" className="text-blue-600">
                    termos de uso
                  </a>
                </label>
              </div>
              {data.error.terms && alert(data.error.terms, "red")}

              {/* Checkbox de Aceitação da Política de Privacidade */}
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={data.privacyAccepted}
                  onChange={handlePrivacyChange}
                  className="mr-2"
                />
                <label>
                  Aceito a{" "}
                  <a href="/privacy" className="text-blue-600">
                    política de privacidade
                  </a>
                </label>
              </div>
              {data.error.privacy && alert(data.error.privacy, "red")}

              {/* Botão de Criação de Conta */}
              <button
                type="button"
                onClick={handleCreateAccount}
                className="mt-4 bg-gray-800 text-white rounded-full px-6 py-2 transition duration-300 ease-in-out focus:outline-none hover:bg-gray-700"
              >
                Criar Conta
              </button>
            </>
          )}
        </form>
      )}
    </Fragment>
  );
};

export default Signup;
