import React, { Fragment, useEffect, useContext, useCallback, useState } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { getAllProduct } from "../../admin/products/FetchApi";
import { HomeContext } from "./index";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTruck } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const apiURL = process.env.REACT_APP_API_URL;

const SingleProduct = () => {
  const { data, dispatch } = useContext(HomeContext);
  const { products, loading } = data;
  const history = useHistory();
  const [hoveredProductId, setHoveredProductId] = useState(null);
  const [wList, setWlist] = useState(JSON.parse(localStorage.getItem("wishList")));

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      const responseData = await getAllProduct();
      if (responseData?.Products) {
        dispatch({ type: "setProducts", payload: responseData.Products });
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    centerMode: true,
    swipeToSlide: true, 
    autoplay: true,
    autoplaySpeed: 3000,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1, arrows: false, centerMode: true } },
    ],
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-24 col-span-2 md:col-span-3 lg:col-span-4">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          />
        </svg>
      </div>
    );
  }

  return (
    <Fragment>
      <Slider {...settings} className="w-full px-2 md:px-4 lg:px-6">
        {products && products.length > 0 ? (
          products.map((item) => (
            <div key={item._id} className="p-2">
              <img
                onClick={() => history.push(`/products/${item._id}`)}
                onMouseEnter={() => setHoveredProductId(item._id)}
                onMouseLeave={() => setHoveredProductId(null)}
                className="w-full object-cover object-center cursor-pointer transition-transform duration-300 ease-in-out transform hover:scale-105"
                src={`${apiURL}/uploads/products/${hoveredProductId === item._id && item.pImages[1] ? item.pImages[1] : item.pImages[0]}`}
                alt={item.pName}
              />
              <div className="flex items-center justify-between mt-2">
                <div className="text-gray-800 text-sm font-light whitespace-nowrap">
                  {item.pName} 
                </div>
                <div className="flex items-center space-x-1">
                  <span className="w-4 h-4 text-blue-500"></span>
                  <span className="text-gray-700">{item.pRatingsReviews.length}</span>
                </div>
              </div>
              {item.pOffer ? (
                <div className="flex items-center mt-2">
                <div className="flex flex-col mr-2">
                  <div className="text-black text-md font-light">
                    3x de <span className="text-green-600">R${(item.pPrice / 3).toFixed(2)}</span> <span className="text-black">sem juros</span>
                  </div>
                  <div className="text-black-500 text-lg font-semibold">ou à vista <span className="text-green-500">R${item.pPrice}</span></div>
                </div>
                <div className="flex items-center bg-green-200 text-green-800 text-xs font-semibold border border-green-600 px-3 py-1 rounded-full ml-2 whitespace-nowrap">
                  <FontAwesomeIcon icon={faTruck} className="mr-1" /> Frete Grátis
                </div>
              </div>
              
              ) : (
                <div className="flex items-center mt-2">
                  <div className="flex flex-col mr-2">
                    <div className="text-green-600 text-md font-light">3x de R${(item.pPrice / 3).toFixed(2)} sem juros</div>
                    <div className="text-gray-900 text-lg font-semibold">R${item.pPrice}</div>
                  </div>
                  <div className="bg-green-200 text-green-800 text-xs font-semibold border border-green-600 px-3 py-1 rounded-full ml-2 whitespace-nowrap">
                    <FontAwesomeIcon icon={faTruck} className="mr-1" /> Frete Grátis
                  </div>
                </div>
              )}
              <div className="absolute top-0 right-0 mx-2 my-2 md:mx-4">
                <svg
                  onClick={(e) => isWishReq(e, item._id, setWlist)}
                  className={`${isWish(item._id, wList) && "hidden"} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <svg
                  onClick={(e) => unWishReq(e, item._id, setWlist)}
                  className={`${!isWish(item._id, wList) && "hidden"} w-5 h-5 md:w-6 md:h-6 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-2 md:col-span-3 lg:col-span-4 flex items-center justify-center py-24 text-2xl text-gray-600">
            Produto não encontrado
          </div>
        )}
      </Slider>
    </Fragment>
  );
};

export default SingleProduct;
