import React, { Fragment, useContext, useEffect, useRef } from "react";
import { useHistory, useLocation } from "react-router-dom";
import "./style.css";

import { logout } from "./Action";
import { LayoutContext } from "../index";
import { isAdmin } from "../auth/fetchApi";

const Navber = (props) => {
  const history = useHistory();
  const location = useLocation();

  const { data, dispatch } = useContext(LayoutContext);

  // Referência para o menu hambúrguer
  const navberRef = useRef(null);

  const navberToggleOpen = () =>
    data.navberHamburger
      ? dispatch({ type: "hamburgerToggle", payload: false })
      : dispatch({ type: "hamburgerToggle", payload: true });

  const loginModalOpen = () =>
    data.loginSignupModal
      ? dispatch({ type: "loginSignupModalToggle", payload: false })
      : dispatch({ type: "loginSignupModalToggle", payload: true });

  const cartModalOpen = () =>
    data.cartModal
      ? dispatch({ type: "cartModalToggle", payload: false })
      : dispatch({ type: "cartModalToggle", payload: true });

  // Adiciona um evento global para fechar o menu ao clicar fora
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        data.navberHamburger &&
        navberRef.current &&
        !navberRef.current.contains(event.target)
      ) {
        dispatch({ type: "hamburgerToggle", payload: false });
      }
    };

    document.addEventListener("click", handleOutsideClick);

    // Limpeza do evento ao desmontar o componente
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [data.navberHamburger, dispatch]);

  return (
    <Fragment>
      {/* Seção de Navegação */}
      <nav className="fixed top-0 w-full z-20 shadow-lg lg:shadow-none bg-white">
        <div className="m-4 md:mx-12 md:my-6 grid grid-cols-4 lg:grid-cols-3">
          {/* Navegação Desktop */}
          <div className="hidden lg:inline-flex col-span-1 text-gray-600 mt-1 space-x-2">
            <span
              className="inline-block px-2 py-2 font-light cursor-pointer hover:text-gray-800"
              onClick={() => history.push("/")}
            >
              Comprar
            </span>

            <span
              className="inline-block px-2 py-2 font-light cursor-pointer hover:text-gray-800"
              onClick={() => history.push("/duvidas-frequentes")}
            >
              Dúvidas Frequentes
            </span>

            <span
              className="inline-block px-2 py-2 font-light cursor-pointer hover:text-gray-800"
              onClick={() => history.push("/rastreador-flitz")}
            >
              Meu Pedido
            </span>
          </div>

          {/* Navegação Mobile e Ícone Hambúrguer */}
          <div
            className="col-span-2 lg:hidden flex justify-items-stretch items-center"
            ref={navberRef} // Adiciona a referência aqui
          >
            <svg
              onClick={(e) => {
                e.stopPropagation(); // Impede o fechamento ao clicar no ícone hambúrguer
                navberToggleOpen();
              }}
              className="col-span-1 lg:hidden w-8 h-8 cursor-pointer text-gray-600"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={1}
                d="M4 6h16M4 12h16M4 18h16"
              />
            </svg>
            <span
              onClick={() => history.push("/")}
              style={{
                letterSpacing: "0.05rem",
                display: "flex",
                alignItems: "center",
              }} // Ajusta o espaçamento das letras e centraliza o símbolo
              className="font-light text-gray-800 text-2xl cursor-pointer px-2"
            >
              Ecos Eyewear
            </span>
          </div>

          {/* Logo no Centro para Desktop */}
          <div
            onClick={() => history.push("/")}
            style={{ letterSpacing: "0.05rem" }} // Ajuste o espaçamento das letras para uma aparência mais fina
            className="hidden lg:flex items-center justify-center text-center text-gray-800 font-light tracking-widest text-2xl cursor-pointer"
          >
            Ecos Eyewear
          </div>

          {/* Botões de Wishlist, Login/Logout e Cart */}
          <div className="flex items-right col-span-2 lg:col-span-1 flex justify-end">
            {/* Botão da Wishlist */}
            <div
              onClick={() => history.push("/lista-de-desejos")}
              className="rounded-lg px-2 py-2 cursor-pointer"
              title="Wishlist"
            >
              <svg
                className={`${
                  location.pathname === "/lista-de-desejos"
                    ? "fill-current text-red-800"
                    : "text-red-800"
                } w-8 h-8 cursor-pointer`}
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                />
              </svg>
            </div>

            {/* Verifica se o usuário está logado */}
            {localStorage.getItem("jwt") ? (
              <Fragment>
                {/* Dropdown de Usuário */}
                <div
                  className="userDropdownBtn px-2 py-2 rounded-lg relative"
                  title="Logout"
                >
                  <svg
                    className="cursor-pointer w-8 h-8 text-gray-600 hover:text-gray-800"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={1}
                      d="M5.121 17.804A13.937 13.937 0 0112 16c2.5 0 4.847.655 6.879 1.804M15 10a3 3 0 11-6 0 3 3 0 016 0zm6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                  <div className="userDropdown absolute right-0 mt-1 bg-gray-100 rounded">
                    {!isAdmin() ? (
                      <Fragment>
                        <ul className="flex flex-col text-gray-700 w-48 shadow-lg">
                          <li
                            onClick={() => history.push("/user/meus-pedidos")}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                                />
                              </svg>
                            </span>
                            <span>Meus Pedidos</span>
                          </li>
                          <li
                            onClick={() => history.push("/user/minha-conta")}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                                />
                              </svg>
                            </span>
                            <span>Minha Conta</span>
                          </li>
                          <li
                            onClick={() => history.push("/lista-de-desejos")}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                                />
                              </svg>
                            </span>
                            <span>Minha Lista de Desejos</span>
                          </li>
                          <li
                            onClick={() => history.push("/user/configurações")}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>Configurações</span>
                          </li>
                          <li
                            onClick={() => logout()}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Sair</span>
                          </li>
                        </ul>
                      </Fragment>
                    ) : (
                      <Fragment>
                        <ul className="flex flex-col text-gray-700 w-48 shadow-lg">
                          <li
                            onClick={() => history.push("/admin/dashboard")}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z"
                                />
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                                />
                              </svg>
                            </span>
                            <span>Painel de Administração</span>
                          </li>
                          <li
                            onClick={() => logout()}
                            className="flex items-center py-2 px-4 hover:bg-gray-400 cursor-pointer"
                          >
                            <span className="mr-2">
                              <svg
                                className="w-6 h-6"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeWidth={1}
                                  d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1"
                                />
                              </svg>
                            </span>
                            <span>Sair</span>
                          </li>
                        </ul>
                      </Fragment>
                    )}
                  </div>
                </div>
              </Fragment>
            ) : (
              /* Botão de Login */
              <div
                onClick={(e) => {
                  e.stopPropagation(); // Impede o fechamento ao clicar no botão de login
                  loginModalOpen();
                }}
                className="cursor-pointer hover:bg-gray-200 px-2 py-2 rounded-lg"
                title="Login"
              >
                <svg
                  className="w-8 h-8 text-gray-600 hover:text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1}
                    d="M11 16l-4-4m0 0l4-4m-4 4h14m-5 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h7a3 3 0 013 3v1"
                  />
                </svg>
              </div>
            )}
            {/* Botão do Carrinho */}
            <div
              onClick={(e) => {
                e.stopPropagation(); // Impede o fechamento ao clicar no botão do carrinho
                cartModalOpen();
              }}
              className="px-2 py-2 rounded-lg relative cursor-pointer"
              title="Cart"
            >
              <svg
                className="w-8 h-8 text-gray-600 hover:text-gray-800"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1}
                  d="M16 11V7a4 4 0 00-8 0v4M5 9h14l1 12H4L5 9z"
                />
              </svg>
              <span className="absolute top-0 ml-6 mt-1 bg-green-700 rounded px-1 text-white text-xs hover:text-gray-200 font-semibold">
                {data.cartProduct !== null ? data.cartProduct.length : 0}
              </span>
            </div>
          </div>
        </div>
        {/* Menu Mobile */}
        <div
          className={
            data.navberHamburger
              ? "px-2 pb-3 md:pb-0 md:px-6 lg:hidden"
              : "hidden px-2 pb-3 md:pb-0 md:px-6 lg:hidden"
          }
        >
          <div className="flex flex-col text-gray-700 space-y-1">
            <span
              className="font-light text-lg tracking-normal hover:text-gray-900 hover:bg-gray-100 px-3 py-2 rounded cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => history.push("/")}
            >
              Comprar
            </span>

            <span
              className="font-light text-lg tracking-normal hover:text-gray-900 hover:bg-gray-100 px-3 py-2 rounded cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => history.push("/duvidas-frequentes")}
            >
              Dúvidas Frequentes
            </span>

            <span
              className="font-light text-lg tracking-normal hover:text-gray-900 hover:bg-gray-100 px-3 py-2 rounded cursor-pointer transition-colors duration-300 ease-in-out"
              onClick={() => history.push("/rastreador-flitz")}
            >
              Meu Pedido
            </span>
          </div>
        </div>
      </nav>
      {/* End Navber Section */}
    </Fragment>
  );
};

export default Navber;
