import React from "react";

const GenderOptions = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-8 my-8 px-4 md:flex-row md:my-12 md:px-6">
      <div className="flex flex-col items-center justify-center gap-6 w-full md:w-1/2">
        {/* Modelo Masculino com Texto Promocional */}
        <div className="relative group w-full h-64 sm:h-80 rounded-lg overflow-hidden transition-shadow duration-500">
          <img
            src="https://ecos.oticavejabvb.com.br/uploads/customize/ABNERE.gif"
            alt="Óculos"
            className="w-full h-full object-contain transition-transform duration-500 transform group-hover:scale-105"
          />
          <div className="absolute inset-0 bg-gradient-to-t from-black via-transparent to-transparent flex items-end justify-between p-4 sm:p-6">
            <div>
              <p className="text-white text-xs sm:text-sm mt-2 opacity-0 group-hover:opacity-100 transition-opacity duration-500">
                Conheça nossa linha de óculos com design sofisticado e confortável.
              </p>
            </div>
          </div>
          {/* Texto Promocional */}
          <div className="absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 text-center opacity-0 group-hover:opacity-100 transition-opacity duration-500">
            <h2 className="text-white font-bold text-xl sm:text-3xl">Ecos Eyewear</h2>
            <p className="text-white text-sm sm:text-lg mt-2">Óculos com 50% de desconto</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GenderOptions;
