export const fetchData = async (cartListProduct, dispatch) => {
  dispatch({ type: "loading", payload: true });
  try {
    let responseData = await cartListProduct();
    if (responseData && responseData.Products) {
      dispatch({ type: "cartProduct", payload: responseData.Products });
    }
  } catch (error) {
    console.log(error);
  } finally {
    dispatch({ type: "loading", payload: false });
  }
};
