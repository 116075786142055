import React from "react";
import Navbar from "../../partials/Navber";
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const Faq = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-24 px-4 md:px-12">
        <h1 className="text-4xl font-bold text-center mb-12">
          Dúvidas Frequentes - Ecos Eyewear
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            1. Quais são as formas de pagamento aceitas?
          </h2>
          <p className="text-lg text-gray-700">
            Na Ecos Eyewear, oferecemos uma experiência de compra segura e
            prática, aceitando pagamentos via cartão de crédito, PIX, boleto e
            débito. Todas as transações são processadas com a confiabilidade do
            Mercado Pago, garantindo a proteção dos seus dados e a segurança nas
            suas compras.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            2. Como é feito o envio dos produtos?
          </h2>
          <p className="text-lg text-gray-700">
            O envio dos produtos é totalmente gerenciado pela Ecos Eyewear,
            utilizando uma variedade de meios, como Correios, transportadoras e
            motoboys, para assegurar que seu pedido chegue até você de forma
            rápida e eficiente. Acompanhamos de perto cada entrega para garantir
            a satisfação de nossos clientes.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            3. Qual é a política de troca e devolução?
          </h2>
          <p className="text-lg text-gray-700">
            Nossa política de troca e devolução é baseada nas diretrizes do
            Código de Defesa do Consumidor. Você pode solicitar a devolução em
            até 7 dias corridos após o recebimento do produto, desde que ele
            esteja em perfeitas condições e na embalagem original. Em caso de
            defeito de fabricação, oferecemos reparo ou troca dentro do prazo de
            garantia estabelecido. Estamos aqui para garantir que você tenha a
            melhor experiência possível com nossos produtos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            4. Como faço para acompanhar meu pedido?
          </h2>
          <p className="text-lg text-gray-700">
            Você pode acompanhar o status do seu pedido de maneira fácil e
            conveniente. Após a confirmação do pagamento e a postagem do
            produto, enviaremos um e-mail com o código de rastreio. Além disso,
            nossa equipe está disponível para te ajudar pelo WhatsApp ou e-mail,
            garantindo que você tenha sempre acesso às informações sobre sua
            compra.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            5. Os produtos possuem garantia?
          </h2>
          <p className="text-lg text-gray-700">
            Sim! Todos os produtos da Ecos Eyewear vêm com uma garantia que
            varia de 6 meses a 1 ano. Esta garantia cobre defeitos de
            fabricação, e nossa equipe está sempre pronta para resolver qualquer
            questão que possa surgir dentro deste período, garantindo a sua
            tranquilidade ao escolher nossos produtos.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            6. Como posso entrar em contato com o suporte?
          </h2>
          <p className="text-lg text-gray-700">
            Estamos aqui para ajudar! Você pode entrar em contato conosco
            através do WhatsApp, Instagram, Facebook ou pelo nosso site. Nossa
            equipe de suporte está sempre pronta para responder suas dúvidas e
            oferecer assistência de forma ágil e eficiente.
          </p>
        </section>
      </div>
      <Footer />
      <CartModal />
    </>
  );
};

export default Faq;
