import React, { Fragment, useState, useContext } from "react";
import { loginReq } from "./fetchApi";
import { LayoutContext } from "../index";

const Login = () => {
  const { dispatch: layoutDispatch } = useContext(LayoutContext);
  const [data, setData] = useState({
    phone: "",
    password: "",
    error: "",
    loading: false,
    validationErrors: {},
  });

  const alert = (msg) => <div className="text-xs text-red-500">{msg}</div>;

  const formatPhoneNumber = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    if (cleaned.length <= 2) return `(${cleaned}`; // DDD
    if (cleaned.length <= 7)
      return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2)}`; // Adiciona espaço
    return `(${cleaned.slice(0, 2)}) ${cleaned.slice(2, 7)}-${cleaned.slice(
      7,
      11
    )}`; // Formatação completa
  };

  const removeThirdDigit = (value) => {
    const cleaned = ("" + value).replace(/\D/g, "");
    return cleaned.slice(0, 2) + cleaned.slice(3); // Remove o terceiro dígito
  };

  const validateForm = () => {
    const errors = {};
    if (!data.phone) {
      errors.phone = "O campo telefone não deve estar vazio.";
    }
    if (!data.password) {
      errors.password = "O campo senha não deve estar vazio.";
    }
    return errors;
  };

  const formSubmit = async () => {
    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setData({ ...data, validationErrors });
      return;
    }

    setData({ ...data, loading: true, error: "", validationErrors: {} });
    try {
      const formattedPhone = removeThirdDigit(data.phone); // Remove o terceiro dígito
      const responseData = await loginReq({
        phone: formattedPhone,
        password: data.password,
      });

      if (responseData.error) {
        setData({
          ...data,
          loading: false,
          error: responseData.error,
          password: "",
        });
      } else if (responseData.token) {
        localStorage.setItem("jwt", JSON.stringify(responseData));
        window.location.href = "/";
      }
    } catch (error) {
      console.error("Erro no processo de login:", error);
      setData({
        ...data,
        loading: false,
        error: "Erro ao tentar fazer login.",
      });
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      formSubmit(); // Chama a função de login ao pressionar Enter
    }
  };

  return (
    <Fragment>
      <div className="text-center text-2xl mb-6">
        Bem-vindo de volta à Ecos Eyewear! Faça seu login
      </div>
      <form className="space-y-4" onSubmit={(e) => e.preventDefault()}>
        <div className="flex flex-col">
          <label htmlFor="phone">
            Número de WhatsApp
            <span className="text-sm text-gray-600 ml-1">*</span>
          </label>
          <input
            onChange={(e) => {
              const formattedNumber = formatPhoneNumber(e.target.value);
              setData({
                ...data,
                phone: formattedNumber,
                error: "",
                validationErrors: {},
              });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            onKeyPress={handleKeyPress}
            value={data.phone}
            type="text"
            id="phone"
            className={`px-4 py-2 border ${
              data.validationErrors.phone ? "border-red-500" : "border-gray-300"
            } focus:outline-none`}
            placeholder="(XX) XXXXX-XXXX"
          />
          {data.validationErrors.phone && alert(data.validationErrors.phone)}
        </div>
        <div className="flex flex-col">
          <label htmlFor="password">
            Senha
            <span className="text-sm text-gray-600 ml-1">*</span>
          </label>
          <input
            onChange={(e) => {
              setData({
                ...data,
                password: e.target.value,
                error: "",
                validationErrors: {},
              });
              layoutDispatch({ type: "loginSignupError", payload: false });
            }}
            onKeyPress={handleKeyPress}
            value={data.password}
            type="password"
            id="password"
            className={`px-4 py-2 border ${
              data.validationErrors.password
                ? "border-red-500"
                : "border-gray-300"
            } focus:outline-none`}
          />
          {data.validationErrors.password &&
            alert(data.validationErrors.password)}
        </div>
        <button
          type="button"
          onClick={formSubmit}
          className="bg-gray-800 text-white font-medium px-4 py-2 text-center cursor-pointer transition duration-300 ease-in-out hover:bg-gray-700"
        >
          {data.loading ? "Fazendo Login..." : "Fazer Login"}
        </button>
        {data.error && alert(data.error)}
      </form>
    </Fragment>
  );
};

export default Login;
