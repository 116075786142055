import React, { Fragment, useContext, useEffect } from "react";
import moment from "moment";

import { OrderContext } from "./index";
import { fetchData, editOrderReq, deleteOrderReq } from "./Actions";

const apiURL = process.env.REACT_APP_API_URL;

const AllCategory = (props) => {
  const { data, dispatch } = useContext(OrderContext);
  const { orders, loading } = data;

  useEffect(() => {
    fetchData(dispatch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log(orders);
  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
        </svg>
      </div>
    );
  }
  return (
    <Fragment>
      <div className="col-span-1 overflow-auto bg-white shadow-lg p-4">
        <table className="table-auto border w-full my-2">
          <thead>
            <tr>
              <th className="px-4 py-2 border">Produto</th>
              <th className="px-4 py-2 border">Status</th>
              <th className="px-4 py-2 border">Valor</th>
              <th className="px-4 py-2 border">Código da Transação</th>
              <th className="px-4 py-2 border">Nome</th>
              <th className="px-4 py-2 border">Sobrenome</th>
              <th className="px-4 py-2 border">CPF</th>
              <th className="px-4 py-2 border">Data de Nascimento</th>
              <th className="px-4 py-2 border">Email</th>
              <th className="px-4 py-2 border">Telefone</th>
              <th className="px-4 py-2 border">Número da Casa</th>
              <th className="px-4 py-2 border">Endereço</th>
              <th className="px-4 py-2 border">Complemento</th>
              <th className="px-4 py-2 border">Bairro</th>
              <th className="px-4 py-2 border">Cidade</th>
              <th className="px-4 py-2 border">Estado</th>
              <th className="px-4 py-2 border">Data da Compra</th>
              <th className="px-4 py-2 border">Última Alteração</th>
              <th className="px-4 py-2 border">Mudar Status</th>
            </tr>
          </thead>
          <tbody>
            {orders && orders.length > 0 ? (
              orders.map((item, i) => (
                <CategoryTable
                  key={i}
                  order={item}
                  editOrder={(oId, type, status) =>
                    editOrderReq(oId, type, status, dispatch)
                  }
                />
              ))
            ) : (
              <tr>
                <td
                  colSpan="19"
                  className="text-xl text-center font-semibold py-8">
                  Nenhum Pedido Encontrado.
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div className="text-sm text-gray-600 mt-2">
          Total de {orders && orders.length} Pedidos Encontrado.
        </div>
      </div>
    </Fragment>
  );
};

/* Single Category Component */
const CategoryTable = ({ order, editOrder }) => {
  const { dispatch } = useContext(OrderContext);

  return (
    <Fragment>
      <tr className="border-b">
        <td className="w-48 hover:bg-gray-200 p-2 flex flex-col space-y-1">
          {order.allProduct.map((product, i) => (
            <span className="block flex items-center space-x-2" key={i}>
              <img
                className="w-8 h-8 object-cover object-center"
                src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                alt="productImage"
              />
              <span>{product.pName}</span>
              <span>{product.quantitiy}x</span>
            </span>
          ))}
        </td>
        <td className="hover:bg-gray-200 p-2 text-center cursor-default">
          <span className={`block rounded-full text-center text-xs px-2 font-semibold ${order.status === "Entregue" ? "text-green-600" : order.status === "Cancelado" ? "text-red-600" : "text-yellow-700"}`}>
            {order.status}
          </span>
        </td>
        <td className="hover:bg-gray-200 p-2 text-center">${order.amount}.00</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.transactionId}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.nome}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.sobrenome}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.cpf}</td>
        <td className="hover:bg-gray-200 p-2 text-center">
          {moment(order.dataNascimento).format("DD/MM/YYYY")}
        </td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.email}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.telefone}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.number}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.address}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.complemento}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.bairro}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.cidade}</td>
        <td className="hover:bg-gray-200 p-2 text-center">{order.estado}</td>
        <td className="hover:bg-gray-200 p-2 text-center">
          {moment(order.createdAt).format("DD/MM/YYYY")}
        </td>
        <td className="hover:bg-gray-200 p-2 text-center">
          {moment(order.updatedAt).format("DD/MM/YYYY")}
        </td>
        <td className="p-2 flex items-center justify-center">
          <span
            onClick={(e) => editOrder(order._id, true, order.status)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
            <svg
              className="w-6 h-6 fill-current text-green-500"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
              <path
                fillRule="evenodd"
                d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z"
                clipRule="evenodd"
              />
            </svg>
          </span>
          <span
            onClick={(e) => deleteOrderReq(order._id, dispatch)}
            className="cursor-pointer hover:bg-gray-200 rounded-lg p-2 mx-1">
            <svg
              className="w-6 h-6 text-red-500"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
              />
            </svg>
          </span>
        </td>
      </tr>
    </Fragment>
  );
};

export default AllCategory;
