export const productDetailsState = {
  loading: false,
  menu: true,
  cartOpen: false,
  cartState: true, // Novo estado para controlar a abertura do carrinho
};

export const productDetailsReducer = (state, action) => {
  switch (action.type) {
    case "menu":
      return {
        ...state,
        menu: action.payload,
      };
    case "loading":
      return {
        ...state,
        loading: action.payload,
      };
    case "cartState":
      return {
        ...state,
        cartState: action.payload,
      };
    case "toggleCart": // Novo case para controlar a visibilidade do carrinho
      return {
        ...state,
        cartOpen: action.payload,
      };
    default:
      return state;
  }
};
