import React from "react";
import Navbar from "../../partials/Navber";
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const Servicos = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-24 px-4 md:px-12">
        <h1 className="text-4xl font-bold text-center mb-12">
          Termos de Serviço - Ecos Eyewear
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">1. Aceitação dos Termos</h2>
          <p className="text-lg text-gray-700">
            Ao acessar e utilizar o site da Ecos Eyewear, você concorda em
            cumprir e se comprometer com estes Termos de Serviço. Se você não
            concorda com qualquer parte destes termos, não deverá usar nosso
            site.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">2. Uso do Site</h2>
          <p className="text-lg text-gray-700">
            Você se compromete a usar este site apenas para fins legais e de
            maneira que não infrinja os direitos de ninguém, nem restrinja ou
            impeça o uso e aproveitamento do site por parte de terceiros.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">3. Conta de Usuário</h2>
          <p className="text-lg text-gray-700">
            Para realizar compras em nosso site, você precisará criar uma conta
            e fornecer informações pessoais. Você é responsável por manter a
            confidencialidade de suas informações de login e por todas as
            atividades que ocorrerem em sua conta.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">4. Produtos e Preços</h2>
          <p className="text-lg text-gray-700">
            Todos os preços dos produtos estão sujeitos a alteração sem aviso
            prévio. A Ecos Eyewear se reserva o direito de modificar ou
            descontinuar produtos a qualquer momento. Os preços incluem
            impostos, a menos que indicado de outra forma.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">5. Políticas de Compra</h2>
          <p className="text-lg text-gray-700">
            Ao realizar uma compra, você concorda em pagar o valor total do
            pedido, incluindo impostos e taxas de envio, se aplicáveis. Após a
            confirmação da compra, você receberá um e-mail com os detalhes do
            pedido.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">6. Trocas e Devoluções</h2>
          <p className="text-lg text-gray-700">
            Nossas políticas de trocas e devoluções são baseadas no Código de
            Defesa do Consumidor. Você tem o direito de solicitar a troca ou
            devolução de um produto em até 7 dias após o recebimento. Para mais
            informações, consulte nossa Política de Trocas e Devoluções.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">7. Responsabilidade</h2>
          <p className="text-lg text-gray-700">
            A Ecos Eyewear não se responsabiliza por danos diretos, indiretos,
            incidentais ou consequenciais decorrentes do uso ou da
            impossibilidade de uso do site ou de qualquer produto adquirido.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            8. Modificações dos Termos
          </h2>
          <p className="text-lg text-gray-700">
            A Ecos Eyewear se reserva o direito de modificar estes Termos de
            Serviço a qualquer momento. Quaisquer alterações serão publicadas
            nesta página e a data da última atualização será indicada no topo do
            documento.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">9. Contato</h2>
          <p className="text-lg text-gray-700">
            Se você tiver dúvidas sobre estes Termos de Serviço, entre em
            contato conosco pelo e-mail contato@ecoseyewear.com.br.
          </p>
        </section>
      </div>
      <Footer />
      <CartModal />
    </>
  );
};

export default Servicos;
