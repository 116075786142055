import React, { Fragment, useContext, useState, useEffect } from "react";
import Layout from "./Layout";
import { DashboardUserContext } from "./Layout";
import { updatePersonalInformationAction } from "./Action";
import { FaWhatsapp } from "react-icons/fa"; // Importando o ícone do WhatsApp

const ProfileComponent = () => {
  const { data, dispatch } = useContext(DashboardUserContext);
  const userDetails = data.userDetails || {};

  const [fData, setFdata] = useState({
    id: "",
    name: "",
    phone: "",
    success: false,
  });

  // Função para formatar o número de telefone
  const formatPhoneNumber = (phoneNumber) => {
    if (!phoneNumber) return '';

    // Remove todos os caracteres que não são dígitos
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');

    // Verifica se o número começa com o código do país '55'
    let formattedNumber = cleaned;
    if (cleaned.startsWith('55')) {
      // Remove o código do país para formatar
      formattedNumber = cleaned.slice(2);
    }

    // Tenta corresponder a 11 dígitos (2 DDD + 9 dígitos)
    let match = formattedNumber.match(/^(\d{2})(\d{5})(\d{4})$/);
    if (match) {
      return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
    }

    // Se não corresponder, tenta corresponder a 10 dígitos (2 DDD + 8 dígitos)
    match = formattedNumber.match(/^(\d{2})(\d{4})(\d{4})$/);
    if (match) {
      return `+55 (${match[1]}) ${match[2]}-${match[3]}`;
    }

    // Se não corresponder a nenhum dos padrões, retorna o número original
    return phoneNumber;
  };

  useEffect(() => {
    const formattedPhone = formatPhoneNumber(userDetails.phone);
    console.log("Número original:", userDetails.phone);
    console.log("Número formatado:", formattedPhone);

    setFdata((prevFdata) => ({
      ...prevFdata,
      id: userDetails._id,
      name: userDetails.name,
      phone: formattedPhone,
    }));
  }, [userDetails]);

  const handleSubmit = () => {
    updatePersonalInformationAction(dispatch, fData);
    setFdata((prevFdata) => ({
      ...prevFdata,
      success: "Informações atualizadas com sucesso!",
    }));
  };

  if (data.loading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="flex flex-col w-full my-4 md:my-0 md:w-9/12 md:px-8">
        <div className="shadow-lg border">
          <div className="py-4 px-4 text-lg font-semibold border-t-2 border-yellow-700">
            Informações Pessoais
          </div>
          <hr />
          <div className="py-4 px-4 md:px-8 lg:px-16 flex flex-col space-y-4">
            {fData.success && (
              <div className="bg-green-200 px-4 py-2 rounded text-green-800">
                {fData.success}
              </div>
            )}
            <div className="flex flex-col space-y-2">
              <label htmlFor="name">Nome Completo</label>
              <input
                onChange={(e) => setFdata({ ...fData, name: e.target.value })}
                value={fData.name}
                type="text"
                id="name"
                className="border px-4 py-2 w-full focus:outline-none"
                placeholder="Digite seu nome completo"
              />
            </div>
            <div className="flex flex-col space-y-2">
              <label htmlFor="phone">Número de Telefone</label>
              <input
                value={fData.phone}
                readOnly
                type="tel"
                id="phone"
                className="cursor-not-allowed border px-4 py-2 bg-gray-200 w-full"
                placeholder="Telefone"
              />
              <span className="text-xs text-gray-500">
                O telefone não pode ser alterado
              </span>
            </div>
            <button
              onClick={handleSubmit}
              className="w-full text-center bg-gray-800 text-gray-100 px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
            >
              Atualizar Informações
            </button>
            {/* Seção de Contato */}
            <div className="mt-4 text-center">
              <span className="text-gray-600">
                Entre em contato com a Ecos Eyewear para saber mais!
              </span>
              <br />
              <a
                href="mailto:ecoseyewear@gmail.com"
                className="text-blue-500 underline"
              >
                ecoseyewear@gmail.com
              </a>
              {/* Botão Discreto de WhatsApp */}
              <div className="mt-4">
                <a
                  href="https://wa.me/559591273913?text=Ol%C3%A1%2C%20estou%20acessando%20a%20sess%C3%A3o%20de%20informações%20pessoais%20no%20site%20ecoseyewear.com.br%20e%20gostaria%20de%20obter%20ajuda."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-3 py-2 bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 transition-colors duration-200"
                  aria-label="Contato via WhatsApp"
                >
                  <FaWhatsapp className="mr-2" />
                  WhatsApp
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

const UserProfile = () => {
  return <Layout children={<ProfileComponent />} />;
};

export default UserProfile;
