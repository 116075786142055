import React from "react";
import Navbar from "../../partials/Navber";
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const Politica = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-24 px-4 md:px-12">
        <h1 className="text-4xl font-light text-center mb-12">
          Política de Privacidade - Ecos Eyewear
        </h1>
        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">1. Introdução</h2>
          <p className="text-lg text-gray-700">
            Na Ecos Eyewear, valorizamos a privacidade dos nossos clientes e
            estamos comprometidos em proteger suas informações pessoais. Esta
            Política de Privacidade explica como coletamos, usamos e protegemos
            suas informações ao utilizar nosso site,{" "}
            <a
              href="https://ecoseyewear.com.br/"
              className="text-blue-500 underline"
            >
              https://ecoseyewear.com.br/
            </a>{" "}
            (o "Site").
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            2. Informações que Coletamos
          </h2>
          <p className="text-lg text-gray-700">
            Coletamos as seguintes informações:
          </p>
          <ul className="list-disc ml-6 text-lg text-gray-700">
            <li>
              <strong>Dados Pessoais:</strong> Quando você cria uma conta ou
              realiza uma compra, coletamos informações como seu nome, endereço
              de e-mail e informações de login.
            </li>
            <li>
              <strong>Informações de Entrega:</strong> Para processar suas
              compras, precisamos do seu endereço de entrega, incluindo o CEP,
              rua, número e cidade.
            </li>
            <li>
              <strong>Informações de Pagamento:</strong> Para processar
              pagamentos, coletamos informações que podem incluir dados de
              cartão de crédito, que são processados por nossos parceiros de
              pagamento.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            3. Como Usamos Suas Informações
          </h2>
          <p className="text-lg text-gray-700">
            Utilizamos suas informações para:
          </p>
          <ul className="list-disc ml-6 text-lg text-gray-700">
            <li>Processar e gerenciar pedidos.</li>
            <li>
              Comunicar-se com você sobre o status do seu pedido e enviar
              confirmações.
            </li>
            <li>Oferecer suporte ao cliente.</li>
            <li>
              Enviar atualizações sobre produtos e promoções (caso tenha optado
              por receber tais comunicações).
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            4. Compartilhamento de Informações
          </h2>
          <p className="text-lg text-gray-700">
            Não vendemos suas informações pessoais. Podemos compartilhar suas
            informações com:
          </p>
          <ul className="list-disc ml-6 text-lg text-gray-700">
            <li>
              <strong>Prestadores de Serviços:</strong> Trabalhamos com empresas
              terceirizadas, como serviços de entrega, para processar pedidos e
              pagamentos.
            </li>
            <li>
              <strong>Cumprimento Legal:</strong> Podemos divulgar suas
              informações quando exigido por lei ou em resposta a processos
              legais.
            </li>
          </ul>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            5. Segurança das Informações
          </h2>
          <p className="text-lg text-gray-700">
            Adotamos medidas de segurança adequadas para proteger suas
            informações pessoais contra acesso não autorizado, uso indevido ou
            divulgação. Nossas transações são protegidas por criptografia SSL.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">6. Seus Direitos</h2>
          <p className="text-lg text-gray-700">
            Você tem o direito de acessar, corrigir ou solicitar a exclusão das
            suas informações pessoais armazenadas em nossos sistemas. Para
            exercer esses direitos, entre em contato conosco.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">7. Retenção de Dados</h2>
          <p className="text-lg text-gray-700">
            Conservaremos suas informações pessoais apenas pelo tempo necessário
            para cumprir os propósitos descritos nesta política, ou conforme
            exigido por lei.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            8. Alterações na Política de Privacidade
          </h2>
          <p className="text-lg text-gray-700">
            Podemos atualizar esta Política de Privacidade de tempos em tempos.
            A versão mais recente estará sempre disponível em nosso Site.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">9. Contato</h2>
          <p className="text-lg text-gray-700">
            Se você tiver alguma dúvida sobre esta Política de Privacidade ou
            sobre como gerenciamos suas informações, entre em contato conosco
            através do e-mail contato@ecoseyewear.com.br.
          </p>
        </section>
      </div>
      <Footer />
      <CartModal />
    </>
  );
};

export default Politica;
