import React from "react";
import ReactDOM from "react-dom/client"; // Importa createRoot do novo react-dom/client
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// Localiza o elemento root
const rootElement = document.getElementById("root");

// Usa createRoot para renderizar o aplicativo no React 18
const root = ReactDOM.createRoot(rootElement);

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

serviceWorker.unregister();
