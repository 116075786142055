import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

export const createOrderMercadopago = async (orderData) => {
  try {
    let res = await axios.post(
      `${apiURL}/api/order/create-order-mercadopago`,
      orderData
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
