import axios from "axios";
const apiURL = process.env.REACT_APP_API_URL;

// Função para verificar se o usuário está autenticado
export const isAuthenticate = () =>
  localStorage.getItem("jwt") ? JSON.parse(localStorage.getItem("jwt")) : false;

// Função para verificar se o usuário é um administrador
export const isAdmin = () =>
  localStorage.getItem("jwt")
    ? JSON.parse(localStorage.getItem("jwt")).user.role === 1
    : false;

// Função de login
export const loginReq = async ({ phone, password }) => {
  const data = { phone, password };
  try {
    const res = await axios.post(`${apiURL}/api/signin`, data);
    return res.data;
  } catch (error) {
    console.error(
      "Erro ao fazer login:",
      error.response?.data || error.message
    );
    throw new Error(
      error.response?.data?.error ||
        "Falha ao fazer login. Verifique suas credenciais."
    );
  }
};

// Função para enviar código de verificação
export const sendVerificationCodeReq = async ({ phone, name }) => {
  try {
    const res = await axios.post(
      `${apiURL}/api/verify-code`,
      { phone, name } // Envia o nome junto com o telefone
    );
    return res.data; // Retorna a resposta da API
  } catch (error) {
    // Verifica se o erro tem uma resposta do servidor
    if (error.response) {
      // A resposta do servidor indicou um erro
      console.log(
        "Erro ao enviar o código de verificação:",
        error.response.data
      );
      throw new Error(
        error.response.data.error || "Erro desconhecido ao enviar o código."
      );
    } else {
      // Erro na configuração da requisição
      console.log("Erro ao enviar o código de verificação:", error.message);
      throw new Error("Erro de rede ou configuração.");
    }
  }
};

export const verifyCodeReq = async ({
  phone,
  code,
  name,
  password,
  cPassword,
}) => {
  try {
    const res = await axios.post(`${apiURL}/api/verify`, {
      phone,
      code,
      name,
      password,
      cPassword,
    });
    return res.data; // Retorna os dados da resposta se a solicitação for bem-sucedida
  } catch (error) {
    // Captura e trata o erro para exibir mensagens mais detalhadas
    if (error.response) {
      // Se a resposta da API estiver disponível, mostra o erro dela
      console.error("Erro ao verificar o código:", error.response.data);
      throw new Error(
        error.response.data.message || "Erro ao verificar o código."
      );
    } else if (error.request) {
      // Se a requisição foi feita, mas não houve resposta
      console.error("Erro na requisição:", error.request);
      throw new Error("Sem resposta da API. Tente novamente.");
    } else {
      // Outro erro (por exemplo, erro de configuração)
      console.error("Erro:", error.message);
      throw new Error("Erro ao processar a solicitação.");
    }
  }
};
