import React from "react";
import Navbar from "../../partials/Navber";
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const Devolu = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-24 px-4 md:px-12">
        <h1 className="text-4xl font-bold text-center mb-12">
          Política de Troca e Devolução - Ecos Eyewear
        </h1>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">
            1. Direito de Arrependimento
          </h2>
          <p className="text-lg text-gray-700">
            De acordo com o Código de Defesa do Consumidor (Lei nº 8.078/1990),
            você tem o direito de desistir da compra realizada em nossa loja
            online no prazo de até 7 (sete) dias corridos a partir do
            recebimento do produto. Para exercer esse direito, o produto deve
            ser devolvido em sua embalagem original, sem indícios de uso,
            acompanhado de nota fiscal e de todos os seus acessórios.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            <strong>Como Solicitar a Devolução:</strong>
          </p>
          <p className="text-lg text-gray-700 mt-2">
            1. Entre em contato com nossa equipe pelo e-mail
            contato@ecoseyewear.com.br dentro do prazo de 7 dias corridos após o
            recebimento do produto.
            <br />
            2. Informe o número do pedido, o motivo da devolução e se deseja
            trocar o produto ou solicitar o reembolso.
            <br />
            3. Nossa equipe fornecerá as instruções para o envio do produto.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            <strong>Reembolso:</strong>
          </p>
          <p className="text-lg text-gray-700 mt-2">
            - O reembolso será realizado utilizando a mesma forma de pagamento
            escolhida no momento da compra.
            <br />
            - Caso o pagamento tenha sido feito via cartão de crédito, o estorno
            poderá ocorrer em até duas faturas subsequentes, dependendo da
            administradora do cartão.
            <br />- Para pagamentos realizados via boleto bancário, o reembolso
            será efetuado por meio de depósito em conta corrente indicada pelo
            consumidor em até 10 dias úteis após o recebimento e análise do
            produto devolvido.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">2. Produtos com Defeito</h2>
          <p className="text-lg text-gray-700">
            Se o produto adquirido apresentar defeito de fabricação dentro do
            prazo de garantia legal de 90 (noventa) dias, conforme previsto no
            artigo 26 do Código de Defesa do Consumidor, o cliente poderá
            solicitar a troca ou reparo do produto.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            <strong>Como Solicitar a Troca por Defeito:</strong>
          </p>
          <p className="text-lg text-gray-700 mt-2">
            1. Entre em contato com nossa equipe pelo e-mail
            contato@ecoseyewear.com.br, informando o número do pedido, a
            descrição do defeito e anexando fotos que demonstrem o problema.
            <br />
            2. Nossa equipe fornecerá as instruções para o envio do produto para
            análise.
          </p>
          <p className="text-lg text-gray-700 mt-4">
            <strong>Análise do Produto:</strong>
          </p>
          <p className="text-lg text-gray-700 mt-2">
            - O produto será analisado pela nossa equipe técnica, e o laudo será
            concluído em até 30 dias corridos após o recebimento do item.
            <br />
            - Caso seja constatado o defeito de fabricação, o cliente poderá
            optar por:
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• Receber um novo produto igual ao
            adquirido, sem custos adicionais.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• Receber um vale-compra no valor do produto
            para utilizar em nossa loja.
            <br />
            &nbsp;&nbsp;&nbsp;&nbsp;• Solicitar o reembolso total do valor pago.
            <br />- Se não for constatado defeito de fabricação, o produto será
            devolvido ao cliente sem direito à substituição ou reembolso.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">3. Condições Gerais</h2>
          <p className="text-lg text-gray-700">
            - Não serão aceitos produtos com indícios de uso, avarias
            decorrentes de mau uso, quedas, ou desgaste natural pelo tempo de
            uso.
            <br />- A Ecos Eyewear se reserva o direito de não aceitar a
            devolução de produtos que não atendam aos critérios acima e de
            enviar o produto de volta ao cliente sem consulta prévia.
          </p>
        </section>

        <section className="mb-8">
          <h2 className="text-2xl font-light mb-4">4. Contato</h2>
          <p className="text-lg text-gray-700">
            Para quaisquer dúvidas ou solicitações, entre em contato conosco
            pelo e-mail contato@ecoseyewear.com.br.
          </p>
        </section>
      </div>
      <Footer />
      <CartModal />
    </>
  );
};

export default Devolu;
