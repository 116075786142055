import {
  getUserById,
  updatePersonalInformationFetch,
  getOrderByUser,
  updatePassword,
} from "./FetchApi";

// Função para realizar o logout do usuário e limpar os dados locais
export const logout = () => {
  localStorage.removeItem("jwt");
  localStorage.removeItem("cart");
  localStorage.removeItem("wishList");
  window.location.href = "/";
};

// Função para buscar dados do usuário e atualizar o estado global
export const fetchData = async (dispatch) => {
  dispatch({ type: "loading", payload: true });
  const jwt = localStorage.getItem("jwt");
  const userId = jwt ? JSON.parse(jwt).user._id : "";

  try {
    const responseData = await getUserById(userId);
    if (responseData?.User) {
      dispatch({ type: "userDetails", payload: responseData.User });
    }
  } catch (error) {
    console.error("Erro ao buscar dados do usuário:", error);
  } finally {
    dispatch({ type: "loading", payload: false });
  }
};

// Função para buscar pedidos do usuário
export const fetchOrderByUser = async (dispatch) => {
  dispatch({ type: "loading", payload: true });
  const jwt = localStorage.getItem("jwt");
  const userId = jwt ? JSON.parse(jwt).user._id : "";

  try {
    const responseData = await getOrderByUser(userId);
    if (responseData?.Order) {
      dispatch({ type: "OrderByUser", payload: responseData.Order });
    }
  } catch (error) {
    console.error("Erro ao buscar pedidos do usuário:", error);
  } finally {
    dispatch({ type: "loading", payload: false });
  }
};

// Função para atualizar informações pessoais do usuário
export const updatePersonalInformationAction = async (dispatch, fData) => {
  const formData = {
    uId: fData.id,
    name: fData.name,
    phoneNumber: fData.phone,
  };
  dispatch({ type: "loading", payload: true });

  try {
    const responseData = await updatePersonalInformationFetch(formData);
    if (responseData?.success) {
      fetchData(dispatch);
    }
  } catch (error) {
    console.error("Erro ao atualizar informações pessoais:", error);
  } finally {
    dispatch({ type: "loading", payload: false });
  }
};

// Função para alteração de senha com validação e feedbacks detalhados
export const handleChangePassword = async (fData, setFdata, dispatch) => {
  if (!fData.newPassword || !fData.oldPassword || !fData.confirmPassword) {
    setFdata((prevData) => ({
      ...prevData,
      error: "Por favor, preencha todos os campos de senha.",
    }));
    return;
  }

  if (fData.newPassword !== fData.confirmPassword) {
    setFdata((prevData) => ({
      ...prevData,
      error: "A nova senha e a confirmação não coincidem.",
    }));
    return;
  }

  const jwt = localStorage.getItem("jwt");
  const userId = jwt ? JSON.parse(jwt).user._id : "";
  const formData = {
    uId: userId,
    oldPassword: fData.oldPassword,
    newPassword: fData.newPassword,
  };
  dispatch({ type: "loading", payload: true });

  try {
    const responseData = await updatePassword(formData);

    if (responseData?.success) {
      setFdata((prevData) => ({
        ...prevData,
        success: responseData.success,
        error: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
    } else if (responseData?.error) {
      setFdata((prevData) => ({
        ...prevData,
        error: responseData.error,
        success: "",
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
      }));
    }
  } catch (error) {
    console.error("Erro ao alterar a senha:", error);
    setFdata((prevData) => ({
      ...prevData,
      error: "Erro ao alterar a senha. Tente novamente mais tarde.",
    }));
  } finally {
    dispatch({ type: "loading", payload: false });
  }
};
