import React, { Fragment, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Layout from "../layout";
import { productByCategory } from "../../admin/products/FetchApi";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { isWishReq, unWishReq, isWish } from "./Mixins";

const apiURL = process.env.REACT_APP_API_URL;

const Submenu = ({ category }) => {
  const history = useHistory();
  return (
    <Fragment>
      {/* Submenu Section */}
      <section className="mx-4 mt-24 md:mx-12 md:mt-32 lg:mt-24">
        <div className="flex justify-between items-center flex-wrap">
          <div className="text-sm flex space-x-3">
            <span
              className="hover:text-yellow-700 cursor-pointer"
              onClick={() => history.push("/")}
            >
              Categoria
            </span>
            <span className="text-yellow-700 cursor-default">{category}</span>
          </div>
          <div className="mt-2 md:mt-0">
            <svg
              className="w-3 h-3"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M13 5l7 7-7 7M5 5l7 7-7 7"
              />
            </svg>
          </div>
        </div>
      </section>
      {/* Submenu Section */}
    </Fragment>
  );
};

const AllProduct = ({ products }) => {
  const history = useHistory();
  const [wList, setWlist] = useState(JSON.parse(localStorage.getItem("wishList")) || []);
  const category = products && products.length > 0 ? products[0].pCategory.cName : "";

  return (
    <Fragment>
      <Submenu category={category} />
      {/* Ajuste da grade para 2 colunas no mobile */}
      <section className="m-4 md:mx-8 md:my-4 grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-4 gap-4">
        {products && products.length > 0 ? (
          products.map((item, index) => (
            <div
              key={index}
              className="relative col-span-1 m-2 bg-white rounded-lg overflow-hidden flex flex-col justify-between"
            >
              <div className="relative w-full flex items-center justify-center bg-gray-100">
                <img
                  onClick={() => history.push(`/products/${item._id}`)}
                  src={`${apiURL}/uploads/products/${item.pImages[0]}`}
                  alt={item.pName}
                  className="w-full h-auto object-contain cursor-pointer rounded-t-lg p-4"
                />
              </div>
              <div className="p-4 flex-grow">
                <h2 className="text-gray-900 text-sm sm:text-base font-semibold truncate mb-2">
                  {item.pName}
                </h2>
                <div className="flex items-center flex-wrap">
                  <div className="text-green-700 text-lg font-bold">R${item.pPrice}</div>
                  {item.pOffer && (
                    <>
                      <span className="text-gray-500 text-sm line-through ml-2">
                        R${item.pOffer}
                      </span>
                      <span className="text-red-500 text-sm font-light ml-2">
                        Em Promoção
                      </span>
                    </>
                  )}
                </div>
                <div className="text-gray-600 text-xs sm:text-sm mt-1 flex items-center">
                  <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                  3x de R${(item.pPrice / 3).toFixed(2)} sem juros
                </div>
              </div>
              <button
                onClick={() => history.push(`/products/${item._id}`)}
                className="w-full bg-green-600 text-white text-center py-2 font-medium hover:bg-green-700 transition-all duration-300 mt-4 rounded-b-lg"
              >
                Comprar agora
              </button>
              <div className="absolute top-2 right-2 flex items-center space-x-2">
                {/* Ícones de Wishlist */}
                <svg
                  onClick={(e) => isWishReq(e, item._id, setWlist)}
                  className={`${isWish(item._id, wList) ? "hidden" : ""} w-5 h-5 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1"
                    d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
                  />
                </svg>
                <svg
                  onClick={(e) => unWishReq(e, item._id, setWlist)}
                  className={`${!isWish(item._id, wList) ? "hidden" : ""} w-5 h-5 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
                  fill="currentColor"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
                    clipRule="evenodd"
                  />
                </svg>
              </div>
            </div>
          ))
        ) : (
          <div className="col-span-2 sm:col-span-3 md:col-span-4 flex items-center justify-center py-24 text-xl sm:text-2xl">
            Nenhum produto encontrado
          </div>
        )}
      </section>
    </Fragment>
  );
};

const PageComponent = () => {
  const [products, setProducts] = useState(null);
  const { catId } = useParams();

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchData = async () => {
    try {
      let responseData = await productByCategory(catId);
      if (responseData && responseData.Products) {
        setProducts(responseData.Products);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Fragment>
      <AllProduct products={products} />
    </Fragment>
  );
};

const ProductByCategory = () => {
  return (
    <Fragment>
      <Layout>
        <PageComponent />
      </Layout>
    </Fragment>
  );
};

export default ProductByCategory;
