import React from "react";
import moment from "moment";
import {
  FaFacebook,
  FaInstagram,
  FaTwitter,
  FaCcVisa,
  FaCcMastercard,
  FaBarcode,
  
} from "react-icons/fa";
import { IoLocationSharp, IoCall, IoMail } from "react-icons/io5";
import { FaPix } from "react-icons/fa6";
const Footer = () => {
  const navigationLinks = [
    { name: "Sobre Nós", href: "/sobre-nos" },
    { name: "Política de Privacidade", href: "/politica-de-privacidade" },
    { name: "Termos e Condições", href: "/termos-e-condicoes" },
    { name: "Política de Trocas e Devoluções", href: "/politica-de-trocas-e-devolucoes" },
  ];

  const socialLinks = [
    { href: "https://facebook.com", icon: <FaFacebook /> },
    { href: "https://instagram.com", icon: <FaInstagram /> },
    { href: "https://twitter.com", icon: <FaTwitter /> },
  ];

  const paymentIcons = [
    { icon: FaCcVisa, name: "Visa" },
    { icon: FaCcMastercard, name: "Mastercard" },
    { icon: FaBarcode, name: "Barcode" },
    { icon: FaPix, name: "Pix" },
  ];

  return (
    <footer className="bg-gray-100 text-gray-700 py-10 px-6 md:px-16">
      <div className="max-w-7xl mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 text-left">
        
        {/* Seção de Navegação */}
        <nav aria-label="Navegação" className="space-y-4">
          <h4 className="text-gray-900 font-semibold">Navegação</h4>
          <ul className="space-y-2">
            {navigationLinks.map((link, index) => (
              <li key={index}>
                <a
                  href={link.href}
                  className="hover:text-green-600 transition-colors duration-200"
                >
                  {link.name}
                </a>
              </li>
            ))}
          </ul>
        </nav>

        {/* Seção de Contato */}
        <address className="not-italic space-y-4">
          <h4 className="text-gray-900 font-semibold">Contato</h4>
          <ul className="space-y-2">
            <li className="flex items-center">
              <IoCall className="mr-2 text-lg text-gray-600" />
              <span>Telefone: (95) 99127-3913</span>
            </li>
            <li className="flex items-center">
              <IoMail className="mr-2 text-lg text-gray-600" />
              <span>E-mail: ecoseyewear@gmail.com</span>
            </li>
            <li className="flex items-center">
              <IoLocationSharp className="mr-2 text-lg text-gray-600" />
              <span>
                Endereço: Avenida Nazaré Filgueiras, 2426A, Pintolândia, Boa Vista, RR
              </span>
            </li>
          </ul>
        </address>

        {/* Seção de Informações Legais e Mídias Sociais */}
        <div className="space-y-4">
          <h4 className="text-gray-900 font-semibold">Informações Legais</h4>
          <ul className="space-y-2">
            <li>CNPJ: 49.793.315/0001-22</li>
            <li>Razão Social: Ótica Aveja Ecos Ltda.</li>
          </ul>

          {/* Mídias Sociais */}
          <div className="mt-4">
            <h4 className="text-gray-900 font-semibold mb-2">Siga-nos</h4>
            <div className="flex space-x-4">
              {socialLinks.map((social, index) => (
                <a
                  key={index}
                  href={social.href}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-gray-600 hover:text-green-600 transition-colors duration-200"
                  aria-label={social.name}
                >
                  {React.cloneElement(social.icon, { size: 24 })}
                </a>
              ))}
            </div>
          </div>
        </div>
      </div>

      {/* Seção de Pagamento e Segurança */}
      <div className="mt-10 max-w-7xl mx-auto text-left">
        <h4 className="text-gray-900 font-semibold mb-4">Formas de Pagamento</h4>
        <div className="flex flex-wrap items-center space-x-4">
          {paymentIcons.map((payment, index) => {
            const IconComponent = payment.icon;
            return (
              <IconComponent
                key={index}
                size={32}
                title={payment.name}
                className="text-gray-600 hover:text-green-600 transition-colors duration-200"
              />
            );
          })}
        </div>
        <p className="mt-2 text-sm text-gray-600">Pagamentos processados por Mercado Pago</p>
        <div className="mt-4 flex items-center">
          <FaBarcode className="mr-2 text-gray-600" />
          <span className="text-sm">Site Seguro - Certificação SSL</span>
        </div>
      </div>

      {/* Copyright */}
      <div className="mt-10 border-t border-gray-300 pt-4 text-center">
        <p className="text-sm text-gray-600">
          &copy; {moment().format("YYYY")} Ótica Aveja Ecos Ltda. - Todos os direitos reservados.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
