import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../layout";
import Slider from "react-slick";
import {
  subTotal,
  quantity,
  totalCost,
  totalQuantity,
  allProduct,
} from "../partials/Mixins";
import Product from "../order/mercadoPagoReact";
import { cartListProduct } from "../partials/FetchApi";
import { createOrderMercadopago } from "./FetchApi";
import { fetchData } from "./Action";

const apiURL = process.env.REACT_APP_API_URL;

// Função de validação de CPF
const isValidCPF = (cpf) => {
  if (typeof cpf !== "string") return false;
  cpf = cpf.replace(/[\s.-]*/g, "");
  if (!cpf || cpf.length !== 11 || cpf.split("").every((c) => c === cpf[0]))
    return false;
  let sum = 0;
  let rest;
  for (let i = 1; i <= 9; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (11 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  if (rest !== parseInt(cpf.substring(9, 10))) return false;
  sum = 0;
  for (let i = 1; i <= 10; i++)
    sum += parseInt(cpf.substring(i - 1, i)) * (12 - i);
  rest = (sum * 10) % 11;
  if (rest === 10 || rest === 11) rest = 0;
  return rest === parseInt(cpf.substring(10, 11));
};

// Função de validação de E-mail
const isValidEmail = (email) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

// Função de validação de Telefone
const isValidPhone = (phone) => {
  const phoneRegex = /^\d{10,11}$/;
  return phoneRegex.test(phone);
};

// Componente de Campo de Entrada Reutilizável
const InputField = ({
  label,
  id,
  type = "text",
  value,
  onChange,
  placeholder,
  error,
  onBlur,
  maxLength,
  pattern,
  inputMode,
  min,
  flex = false,
}) => {
  return (
    <div className={`${flex ? "w-full md:w-1/2 md:pr-2" : "w-full"}`}>
      <label htmlFor={id} className="block text-gray-700 text-sm">
        {label}
      </label>
      <input
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        type={type}
        id={id}
        maxLength={maxLength}
        pattern={pattern}
        inputMode={inputMode}
        min={min}
        className={`mt-1 block w-full px-3 py-2 border ${
          error ? "border-red-500" : "border-gray-300"
        } rounded-md bg-white focus:outline-none focus:ring-2 focus:ring-green-500`}
        placeholder={placeholder}
      />
      {error && <span className="text-red-500 text-xs">{error}</span>}
    </div>
  );
};

export const CheckoutComponent = () => {
  const { data, dispatch } = useContext(LayoutContext);
  const [state, setState] = useState({
    nome: "",
    sobrenome: "",
    cpf: "",
    telefone: "",
    email: "",
    dataNascimento: "",
    cep: "",
    address: "",
    number: "",
    complemento: "",
    bairro: "",
    cidade: "",
    estado: "",
  });
  const [errors, setErrors] = useState({});
  const [mercadopago, setStateMercadoPago] = useState("");
  const [step, setStep] = useState(1); // Step 1: Informações Pessoais, Step 2: Endereço, Step 3: Pagamento

  useEffect(() => {
    const fetchCartProducts = async () => {
      try {
        await fetchData(cartListProduct, dispatch);
      } catch (error) {
        console.error("Erro ao buscar produtos do carrinho:", error);
      }
    };
    fetchCartProducts();
  }, [dispatch]);

  const handleButtonClick = () => {
    if (step === 1 && !isStep1Valid()) {
      return;
    }
    if (step === 2 && !isStep2Valid()) {
      return;
    }
    if (step === 3) {
      console.log("Botão 'Finalizar' clicado.");
      generateMercadoPagoButton();
    } else {
      setStep(step + 1);
    }
  };

  const isStep1Valid = () => {
    const { nome, sobrenome, cpf, telefone, email, dataNascimento } = state;
    const newErrors = {};

    if (!nome.trim()) newErrors.nome = "Nome é obrigatório";
    if (!sobrenome.trim()) newErrors.sobrenome = "Sobrenome é obrigatório";
    if (!cpf.trim()) {
      newErrors.cpf = "CPF é obrigatório";
    } else if (!isValidCPF(cpf)) {
      newErrors.cpf = "CPF inválido";
    }

    if (!telefone.trim()) {
      newErrors.telefone = "Telefone é obrigatório";
    } else if (!isValidPhone(telefone)) {
      newErrors.telefone = "Telefone inválido. Deve conter 10 ou 11 dígitos.";
    }

    if (!email.trim()) {
      newErrors.email = "E-mail é obrigatório";
    } else if (!isValidEmail(email)) {
      newErrors.email = "E-mail inválido.";
    }

    if (!dataNascimento) newErrors.dataNascimento = "Data de Nascimento é obrigatória";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const isStep2Valid = () => {
    const { cep, address, number, bairro, cidade, estado } = state;
    const newErrors = {};

    if (!cep.trim()) newErrors.cep = "CEP é obrigatório";
    if (!address.trim()) newErrors.address = "Endereço é obrigatório";
    if (!number.trim()) {
      newErrors.number = "Número é obrigatório";
    } else if (parseInt(number) < 0) {
      newErrors.number = "Número não pode ser negativo";
    }

    if (!bairro.trim()) newErrors.bairro = "Bairro é obrigatório";
    if (!cidade.trim()) newErrors.cidade = "Cidade é obrigatória";
    if (!estado.trim()) newErrors.estado = "Estado é obrigatório";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleCepBlur = async () => {
    if (state.cep.length === 8) {
      try {
        const response = await fetch(`https://viacep.com.br/ws/${state.cep}/json/`);
        const data = await response.json();
        if (!data.erro) {
          setState((prevState) => ({
            ...prevState,
            address: data.logradouro || prevState.address,
            bairro: data.bairro || prevState.bairro,
            cidade: data.localidade || prevState.cidade,
            estado: data.uf || prevState.estado,
          }));
          // Limpar possíveis erros após preenchimento automático
          setErrors((prevErrors) => ({ ...prevErrors, cep: undefined }));
        } else {
          setErrors((prevErrors) => ({ ...prevErrors, cep: "CEP não encontrado." }));
        }
      } catch (error) {
        console.error("Erro ao buscar endereço pelo CEP:", error);
        setErrors((prevErrors) => ({ ...prevErrors, cep: "Erro ao buscar CEP." }));
      }
    }
  };

  const generateMercadoPagoButton = async () => {
    dispatch({ type: "loading", payload: true });

    const {
      address,
      nome,
      sobrenome,
      number,
      dataNascimento,
      cep,
      email,
      cpf,
      telefone,
      complemento,
      bairro,
      cidade,
      estado,
    } = state;

    const orderData = {
      allProduct: allProduct(data.cartProduct),
      user: JSON.parse(localStorage.getItem("jwt")).user._id,
      amount: totalCost(),
      address,
      nome,
      sobrenome,
      email,
      telefone,
      dataNascimento,
      cpf,
      number,
      cep,
      complemento,
      bairro,
      cidade,
      estado,
      quantity: totalQuantity(),
    };

    try {
      const globalId = await createOrderMercadopago(orderData);
      if (globalId.success) {
        setStateMercadoPago(globalId.id);
        console.log("ID do Mercado Pago recebido com sucesso:", globalId.id);
      } else {
        console.error("Falha ao criar pedido no Mercado Pago:", globalId.message);
      }
    } catch (error) {
      console.error("Erro ao processar pagamento:", error);
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  };

  if (data.loading) {
    return (
      <div className="flex items-center justify-center h-screen bg-gray-100 pt-16">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
        <span className="ml-2 text-gray-600">
          Carregando formulário de pagamento Ecos Eyewaer...
        </span>
      </div>
    );
  }

  return (
    <section className="mx-4 mt-16 md:mx-12 md:mt-24 lg:mt-20 bg-white p-4 md:p-6 rounded-lg shadow-sm">
      <div className="text-2xl font-bold text-center text-gray-800 mb-6">
        Finalizar Compra - Ecos Eyewaer
      </div>

      {/* Indicador de Passos */}
      <div className="flex justify-center mb-6">
        <div className="flex items-center space-x-2">
          <div
            className={`flex items-center justify-center rounded-full h-8 w-8 text-sm font-medium border ${
              step >= 1 ? "bg-gray-800 text-white" : "bg-gray-200 text-gray-500"
            }`}
          >
            1
          </div>
          <div
            className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
              step >= 2 ? "border-gray-800" : "border-gray-300"
            }`}
          ></div>
          <div
            className={`flex items-center justify-center rounded-full h-8 w-8 text-sm font-medium border ${
              step >= 2 ? "bg-gray-800 text-white" : "bg-gray-200 text-gray-500"
            }`}
          >
            2
          </div>
          <div
            className={`flex-auto border-t-2 transition duration-500 ease-in-out ${
              step >= 3 ? "border-gray-800" : "border-gray-300"
            }`}
          ></div>
          <div
            className={`flex items-center justify-center rounded-full h-8 w-8 text-sm font-medium border ${
              step >= 3 ? "bg-gray-800 text-white" : "bg-gray-200 text-gray-500"
            }`}
          >
            3
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row lg:space-x-6">
        <div className="lg:w-1/2 mb-6 lg:mb-0">
          <CheckoutProducts products={data.cartProduct} />
        </div>
        <div className="lg:w-1/2">
          <div className="p-4 md:p-6 bg-gray-50 rounded-md shadow-sm">
            {/* Etapas do Formulário */}
            {step === 1 && (
              <form>
                <h2 className="text-lg font-semibold mb-4 text-gray-800">
                  Informações Pessoais
                </h2>
                <div className="flex flex-col space-y-4">
                  {/* Nome e Sobrenome lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="Nome"
                      id="nome"
                      value={state.nome}
                      onChange={(e) =>
                        setState({ ...state, nome: e.target.value })
                      }
                      placeholder="Seu nome..."
                      error={errors.nome}
                      flex={true}
                    />
                    <InputField
                      label="Sobrenome"
                      id="sobrenome"
                      value={state.sobrenome}
                      onChange={(e) =>
                        setState({ ...state, sobrenome: e.target.value })
                      }
                      placeholder="Seu sobrenome..."
                      error={errors.sobrenome}
                      flex={true}
                    />
                  </div>

                  {/* CPF e Data de Nascimento lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="CPF"
                      id="cpf"
                      type="tel"
                      value={state.cpf}
                      onChange={(e) =>
                        setState({ ...state, cpf: e.target.value })
                      }
                      placeholder="Seu CPF..."
                      error={errors.cpf}
                      pattern="\d{11}"
                      inputMode="numeric"
                      flex={true}
                    />
                    <InputField
                      label="Data de Nascimento"
                      id="dataNascimento"
                      type="date"
                      value={state.dataNascimento}
                      onChange={(e) =>
                        setState({
                          ...state,
                          dataNascimento: e.target.value,
                        })
                      }
                      error={errors.dataNascimento}
                      flex={true}
                    />
                  </div>

                  {/* Telefone e E-mail lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="Telefone"
                      id="telefone"
                      type="tel"
                      value={state.telefone}
                      onChange={(e) =>
                        setState({ ...state, telefone: e.target.value })
                      }
                      placeholder="Seu telefone..."
                      error={errors.telefone}
                      pattern="\d{10,11}"
                      inputMode="tel"
                      flex={true}
                    />
                    <InputField
                      label="E-mail"
                      id="email"
                      type="email"
                      value={state.email}
                      onChange={(e) =>
                        setState({ ...state, email: e.target.value })
                      }
                      placeholder="Seu e-mail..."
                      error={errors.email}
                      flex={true}
                    />
                  </div>
                </div>
              </form>
            )}

            {step === 2 && (
              <form>
                <h2 className="text-lg font-semibold mb-4 text-gray-800">
                  Informações de Entrega
                </h2>
                <div className="flex flex-col space-y-4">
                  {/* CEP e Endereço lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="CEP"
                      id="cep"
                      type="tel"
                      value={state.cep}
                      onChange={(e) =>
                        setState({ ...state, cep: e.target.value })
                      }
                      onBlur={handleCepBlur}
                      placeholder="Digite seu CEP..."
                      error={errors.cep}
                      pattern="\d{8}"
                      inputMode="numeric"
                      maxLength="8"
                      flex={true}
                    />
                    <InputField
                      label="Endereço"
                      id="address"
                      value={state.address}
                      onChange={(e) =>
                        setState({ ...state, address: e.target.value })
                      }
                      placeholder="Seu endereço..."
                      error={errors.address}
                      flex={true}
                    />
                  </div>

                  {/* Número e Complemento lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="Número"
                      id="number"
                      type="number"
                      value={state.number}
                      onChange={(e) =>
                        setState({ ...state, number: e.target.value })
                      }
                      placeholder="Número..."
                      error={errors.number}
                      min="0"
                      flex={true}
                    />
                    <InputField
                      label="Complemento"
                      id="complemento"
                      type="text"
                      value={state.complemento}
                      onChange={(e) =>
                        setState({ ...state, complemento: e.target.value })
                      }
                      placeholder="Complemento..."
                      flex={true}
                    />
                  </div>

                  {/* Bairro e Cidade lado a lado */}
                  <div className="flex flex-col md:flex-row md:space-x-4">
                    <InputField
                      label="Bairro"
                      id="bairro"
                      value={state.bairro}
                      onChange={(e) =>
                        setState({ ...state, bairro: e.target.value })
                      }
                      placeholder="Bairro..."
                      error={errors.bairro}
                      flex={true}
                    />
                    <InputField
                      label="Cidade"
                      id="cidade"
                      value={state.cidade}
                      onChange={(e) =>
                        setState({ ...state, cidade: e.target.value })
                      }
                      placeholder="Cidade..."
                      error={errors.cidade}
                      flex={true}
                    />
                  </div>

                  {/* Estado */}
                  <InputField
                    label="Estado"
                    id="estado"
                    value={state.estado}
                    onChange={(e) =>
                      setState({ ...state, estado: e.target.value })
                    }
                    placeholder="Estado..."
                    error={errors.estado}
                    flex={false}
                  />
                </div>
              </form>
            )}

            {step === 3 && mercadopago && <Product id={mercadopago} />}

            {/* Botão de Navegação */}
            <div className="mt-6">
              <button
                onClick={handleButtonClick}
                className="w-full px-4 py-2 bg-gray-800 text-white font-semibold rounded-md hover:bg-gray-700 transition duration-300"
              >
                {step === 3 ? "Finalizar Compra" : "Próximo"}
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

const CheckoutProducts = ({ products }) => {
  const history = useHistory();

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className="bg-gray-100 shadow-sm rounded-md p-4">
      <h2 className="text-lg font-semibold mb-4 text-gray-800">
        Produtos no Carrinho
      </h2>
      <Slider {...settings}>
        {products && products.length > 0 ? (
          products.map((product) => (
            <div
              key={product._id}
              className="p-4 border-b border-gray-300 last:border-b-0"
            >
              <div className="flex flex-col md:flex-row items-center">
                <img
                  onClick={() => history.push(`/products/${product._id}`)}
                  className="cursor-pointer h-20 w-20 object-cover rounded-md"
                  src={`${apiURL}/uploads/products/${product.pImages[0]}`}
                  alt={product.pName}
                />
                <div className="mt-4 md:mt-0 md:ml-4 text-center md:text-left">
                  <div className="text-md font-medium text-gray-800">
                    {product.pName}
                  </div>
                  <div className="text-gray-600 text-sm">
                    Preço: R$ {product.pPrice.toFixed(2)}
                  </div>
                  <div className="text-gray-600 text-sm">
                    Quantidade: {quantity(product._id)}
                  </div>
                  <div className="text-gray-600 text-sm">
                    SubTotal: R$ {subTotal(product._id, product.pPrice).toFixed(2)}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center text-gray-600">
            Nenhum produto encontrado para checkout
          </div>
        )}
      </Slider>
      <div className="mt-4 text-right text-lg font-semibold text-green-600">
        Total: R$ {totalCost().toFixed(2)}
      </div>
    </div>
  );
};

export default CheckoutComponent;
