import React from "react";
import {
  Home,
  WishList,
  ProtectedRoute,
  AdminProtectedRoute,
  CartProtectedRoute,
  PageNotFound,
  ProductDetails,
  ProductByCategory,
  CheckoutPage,
  Sobrenos,
  Politica,
  Servicos,
  Devolu,
  Faq,
  Status,
} from "./shop";

import { DashboardAdmin, Categories, Products, Orders } from "./admin";
import { UserProfile, UserOrders, SettingUser } from "./shop/dashboardUser";
import DetailPayment from "./shop/home/DetailPayment";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

/* Routing All page will be here */
const Routes = (props) => {
  return (
    <Router>
      <Switch>
        {/* Shop & Public Routes */}
        <Route exact path="/" component={Home} />
        <Route exact path="/sobre-nos" component={Sobrenos} />
        <Route exact path="/politica-de-privacidade" component={Politica} />
        <Route exact path="/politica-de-trocas-e-devolucoes" component={Devolu} />
        <Route exact path="/termos-e-condicoes" component={Servicos} />
        <Route exact path="/duvidas-frequentes" component={Faq} />
        <Route exact path="/rastreador-flitz" component={Status} />
        <Route exact path="/lista-de-desejos" component={WishList} />
        <Route exact path="/products/:id" component={ProductDetails} />
        <Route
          exact
          path="/products/category/:catId"
          component={ProductByCategory}
        />
        <CartProtectedRoute
          exact={true}
          path="/checkout"
          component={CheckoutPage}
        />
        {/* Shop & Public Routes End */}

        {/* Admin Routes */}
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard"
          component={DashboardAdmin}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/categories"
          component={Categories}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/products"
          component={Products}
        />
        <AdminProtectedRoute
          exact={true}
          path="/admin/dashboard/orders"
          component={Orders}
        />
        {/* Admin Routes End */}
        <Route exact={true} path="/pays" component={DetailPayment} />
        {/* User Dashboard */}
        <ProtectedRoute
          exact={true}
          path="/user/minha-conta"
          component={UserProfile}
        />
        <ProtectedRoute
          exact={true}
          path="/user/meus-pedidos"
          component={UserOrders}
        />
        <ProtectedRoute
          exact={true}
          path="/user/configurações"
          component={SettingUser}
        />
        {/* User Dashboard End */}

        {/* 404 Page */}
        <Route component={PageNotFound} />
      </Switch>
    </Router>
  );
};

export default Routes;
