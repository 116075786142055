import React, { createContext, useReducer } from "react";
import Layout from "../layout";
import Slider from "./Slider";
import ProductCategory from "./ProductCategory";
import { homeState, homeReducer } from "./HomeContext";
import SingleProduct from "./SingleProduct";
import GenderOptions from "./GenderOptions";
import ProductCard from "./ProductCard";
import FeaturesSection from "./FeaturesSection";
import WhatsAppButton from "./WhatsAppButton";

// Criação do Contexto
export const HomeContext = createContext();

const HomeComponent = () => (
  <>
    {/* Seção do Slider */}
    <section className="mb-2">
      <Slider />
    </section>

    {/* Seção de Categoria de Produtos */}
    <section className="m-4 md:mx-8 md:my-6">
      <ProductCategory />
    </section>

    {/* Seção de Grade de Produtos */}
    <section className="mt-8">
      <SingleProduct />
    </section>

    <section className="my-8 bg-gray-50 py-8">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-4">
          Campanha do Mês - Black Friday na Ecos
        </h2>
        <GenderOptions />
      </div>
    </section>
    {/* Seção de Produtos em Destaque */}
    <section className="my-8 py-8 bg-white">
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-2xl font-semibold text-center text-gray-800 mb-6">
          Produtos em Destaque
        </h2>
        <ProductCard />
      </div>
    </section>

    {/* Seção de Funcionalidades */}
    <section className="mt-8">
      <FeaturesSection />
    </section>

    {/* Botão do WhatsApp */}
    
    <WhatsAppButton phoneNumber="559591273913?text=Olá,%20visitei%20o%20site%20da%20ecoseyewear.com.br%20e%20gostaria%20de%20saber%20mais%20sobre%20os%20produtos.%20Pode%20me%20ajudar?" />
  </>
);

const Home = () => {
  const [data, dispatch] = useReducer(homeReducer, homeState);

  return (
    <HomeContext.Provider value={{ data, dispatch }}>
      <Layout>
        <HomeComponent />
      </Layout>
    </HomeContext.Provider>
  );
};

export default Home;
