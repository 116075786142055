export const subTotal = (id, price) => {
  let subTotalCost = 0;
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.forEach((item) => {
    if (item.id === id) {
      subTotalCost = item.quantitiy * price;
    }
  });
  return subTotalCost;
};
export const cartList = () => {
  const cart = localStorage.getItem("cart")
    ? JSON.parse(localStorage.getItem("cart"))
    : [];
  return cart;
};

export const quantity = (id) => {
  let product = 0;
  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.forEach((item) => {
    if (item.id === id) {
      product = item.quantitiy;
    }
  });
  return product;
};

export const totalCost = () => {
  let totalCost = 0;
  let carts = JSON.parse(localStorage.getItem("cart")) || []; // Define uma lista vazia se 'cart' for null

  carts.forEach((item) => {
    totalCost += item.quantitiy * item.price; // Corrige também 'quantitiy' para 'quantity'
  });

  return totalCost;
};

export const totalQuantity = () => {
  let totalQuantity = 0;

  let carts = JSON.parse(localStorage.getItem("cart"));
  carts.forEach((item) => {
    totalQuantity += item.quantitiy;
  });
  return totalQuantity;
};

export const allProduct = (products) => {
  let carts = JSON.parse(localStorage.getItem("cart"));
  for (let i = 0; i < products.length; i++) {
    for (let j = 0; j < carts.length; j++) {
      console.log(products[i]._id, carts[j]);
      if (products[i]._id === carts[j].id) {
        products[i].pQuantity = carts[j].quantitiy;
      }
    }
  }

  console.log(products);
  return products;
};
