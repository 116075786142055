import React from "react";
import { Route, Redirect } from "react-router-dom";
import { isAuthenticate, isAdmin } from "./fetchApi";

// Componente de rota protegida para administradores
const AdminProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) => {
      // Verifica se o usuário está autenticado e se é um administrador
      if (isAuthenticate()) {
        if (isAdmin()) {
          return <Component {...props} />; // Renderiza o componente se o usuário for um admin
        } else {
          return (
            <Redirect
              to={{
                pathname: "/user/profile", // Redireciona se não for admin
                state: { from: props.location },
              }}
            />
          );
        }
      } else {
        // Redireciona para o login se não estiver autenticado
        return (
          <Redirect
            to={{
              pathname: "/login", // Altere para o caminho correto do login
              state: { from: props.location },
            }}
          />
        );
      }
    }}
  />
);

export default AdminProtectedRoute;
