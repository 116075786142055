import React, { Fragment, useState, useEffect, useContext, useCallback } from "react";
import { useParams } from "react-router-dom";
import { ProductDetailsContext } from "./index";
import { LayoutContext } from "../layout";
import Submenu from "./Submenu";
import ProductDetailsSectionTwo from "./ProductDetailsSectionTwo";
import { FaInstagram } from "react-icons/fa";
import { getSingleProduct } from "./FetchApi";
import { cartListProduct } from "../partials/FetchApi";
import { updateQuantity, slideImage, addToCart, cartList } from "./Mixins";
import { totalCost } from "../partials/Mixins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
// Importando ícones do React Icons
import { FaShippingFast, FaShieldAlt, FaWhatsapp } from "react-icons/fa";

const apiURL = process.env.REACT_APP_API_URL;

const ProductDetailsSection = () => {
  const { id } = useParams();

  const { data, dispatch } = useContext(ProductDetailsContext);
  const { data: layoutData, dispatch: layoutDispatch } = useContext(LayoutContext);

  const sProduct = layoutData?.singleProductDetail;
  const [pImages, setPimages] = useState(null);
  const [count, setCount] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [, setAlert] = useState(false);

  const fetchCartProduct = useCallback(async () => {
    try {
      const responseData = await cartListProduct();
      if (responseData && responseData.Products) {
        layoutDispatch({ type: "cartProduct", payload: responseData.Products });
      }
    } catch (error) {
      console.error(error);
    }
  }, [layoutDispatch]);

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      const responseData = await getSingleProduct(id);
      setTimeout(() => {
        if (responseData?.Product) {
          layoutDispatch({ type: "singleProductDetail", payload: responseData.Product });
          setPimages(responseData.Product.pImages);
          dispatch({ type: "loading", payload: false });
          layoutDispatch({ type: "inCart", payload: cartList() });
        }
        if (responseData?.error) {
          console.error(responseData.error);
        }
      }, 500);
    } catch (error) {
      console.error(error);
    }
    fetchCartProduct();
  }, [id, dispatch, layoutDispatch, fetchCartProduct]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleAddToCart = (event) => {
    event.preventDefault();

    addToCart(
      sProduct._id,
      quantity,
      sProduct.pPrice,
      layoutDispatch,
      setQuantity,
      setAlert,
      fetchData,
      totalCost
    );

    layoutDispatch({ type: "toggleCart", payload: true });
  };

  if (data?.loading) {
    return (
      <div className="flex items-center justify-center h-screen">
        <svg
          className="w-12 h-12 animate-spin text-gray-600 mb-2"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
        </svg>
        <span className="text-gray-700 text-lg">Carregando detalhes do produto...</span>
      </div>
    );
  } else if (!sProduct) {
    return <div className="text-center mt-10 text-gray-600">Produto não encontrado</div>;
  }

  return (
    <Fragment>
      <Submenu
        value={{
          categoryId: sProduct.pCategory._id,
          product: sProduct.pName,
          category: sProduct.pCategory.cName,
        }}
      />
      <section className="m-4 md:mx-12 md:my-6">
        <div className="grid grid-cols-1 md:grid-cols-12 gap-6">
          {/* Bloco da imagem principal e miniaturas */}
          <div className="md:col-span-7">
            <div className="relative">
              <img
                className="w-full md:max-w-md mx-auto"
                src={`${apiURL}/uploads/products/${sProduct.pImages[count]}`}
                alt="Imagem do Produto"
              />
              <div className="absolute inset-0 flex justify-between items-center">
                <svg
                  onClick={() => slideImage("decrease", null, count, setCount, pImages)}
                  className="w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
                </svg>
                <svg
                  onClick={() => slideImage("increase", null, count, setCount, pImages)}
                  className="w-12 h-12 text-gray-700 cursor-pointer hover:text-yellow-700"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
                </svg>
              </div>
            </div>

            {/* Miniaturas das imagens - sempre em linha */}
            <div className="mt-4 flex flex-row space-x-4 overflow-x-auto">
              {pImages &&
                pImages.map((image, index) => (
                  <img
                    key={index}
                    onClick={() => slideImage("increase", index, count, setCount, pImages)}
                    className={`cursor-pointer w-20 h-20 object-cover object-center ${
                      count === index ? "border-4 border-blue-500" : "opacity-50"
                    }`}
                    src={`${apiURL}/uploads/products/${image}`}
                    alt={`Imagem ${index + 1} do Produto`}
                  />
                ))}
            </div>
          </div>

          {/* Bloco das informações do produto */}
          <div className="md:col-span-4 flex flex-col">
            <div className="leading-8">
              <div className="flex items-center space-x-4">
                <span className="text-xl font-semibold text-blue-900 tracking-wider">
                  {sProduct.pName}
                </span>
              </div>

              {/* Bloco de Preços e Descontos */}
              <div className="mt-2">
                <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-4">
                  {/* Preço Original e Preço de Venda */}
                  <div className="flex items-center space-x-2">
                    <span className="line-through text-base text-red-500">R${sProduct.pOffer}</span>
                    <span className="text-2xl font-bold text-green-800">R${sProduct.pPrice}</span>
                  </div>

                  {/* Informação de Parcelamento */}
                  <div className="flex items-center text-sm text-gray-600 mt-2 sm:mt-0">
                    <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
                    <span>3x de R${(sProduct.pPrice / 3).toFixed(2)} sem juros</span>
                  </div>

                  {/* Badge de Desconto */}
                  <div className="mt-2 sm:mt-0 bg-green-600 text-white px-3 py-1 rounded-full text-xs font-medium">
                    Economize {(
                      ((parseFloat(sProduct.pOffer) - parseFloat(sProduct.pPrice)) /
                        parseFloat(sProduct.pOffer)) *
                      100
                    ).toFixed(0)}%
                  </div>
                </div>
              </div>

              <div className="text-lg text-green-700 font-bold mt-4">Frete grátis para Boa Vista!</div>

              {/* Seção de Quantidade e Adicionar ao Carrinho */}
              <div className="my-6">
                {+quantity === +sProduct.pQuantity ? (
                  <span className="text-xs text-red-500">Estoque limitado</span>
                ) : (
                  ""
                )}
                <div className="flex justify-between items-center px-4 py-2 border rounded-md">
                  <span>Quantidade</span>
                  {sProduct.pQuantity !== 0 ? (
                    <Fragment>
                      <div className="flex items-center space-x-3">
                        <button
                          onClick={() =>
                            updateQuantity("decrease", sProduct.pQuantity, quantity, setQuantity, setAlert)
                          }
                          className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors duration-200"
                          disabled={quantity <= 1}
                        >
                          <i className="fas fa-minus"></i>
                        </button>
                        <span className="font-semibold">{quantity}</span>
                        <button
                          onClick={() =>
                            updateQuantity("increase", sProduct.pQuantity, quantity, setQuantity, setAlert)
                          }
                          className="bg-gray-200 p-2 rounded-full hover:bg-gray-300 transition-colors duration-200"
                          disabled={quantity >= sProduct.pQuantity}
                        >
                          <i className="fas fa-plus"></i>
                        </button>
                      </div>
                    </Fragment>
                  ) : (
                    <div className="text-red-500">Fora de estoque</div>
                  )}
                </div>

                {sProduct.pQuantity !== 0 ? (
                  layoutData.inCart && layoutData.inCart.includes(sProduct._id) ? (
                    <div className="bg-gray-600 px-4 py-2 mt-4 text-white text-center cursor-not-allowed uppercase rounded-md">
                      Produto já adicionado
                    </div>
                  ) : (
                    <button
                      onClick={(event) => handleAddToCart(event)}
                      className="bg-orange-500 px-4 py-2 mt-4 text-white text-center cursor-pointer uppercase rounded-md hover:bg-orange-600 transition-colors duration-300"
                    >
                      <i className="fas fa-shopping-cart mr-2"></i> Adicionar ao carrinho
                    </button>
                  )
                ) : (
                  <div className="bg-gray-800 px-4 py-2 mt-4 text-white text-center uppercase opacity-50 cursor-not-allowed rounded-md">
                    Fora de estoque
                  </div>
                )}
              </div>

              {/* Seção de Informações Adicionais */}
              <div className="mt-6 p-4 bg-gray-100 rounded-md">
                <div className="flex items-center space-x-3 mb-2">
                  <FaShippingFast className="text-green-600 text-xl" />
                  <div>
                    <span className="font-semibold">Frete Grátis</span>
                    <p className="text-sm text-gray-600">Entrega para Boa Vista em 7 a 10 dias</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3 mb-2">
                  <FaShieldAlt className="text-green-600 text-xl" />
                  <div>
                    <span className="font-semibold">Garantia</span>
                    <p className="text-sm text-gray-600">1 ano contra defeitos de fabricação</p>
                  </div>
                </div>
                <div className="flex items-center space-x-3">
                  <FaWhatsapp className="text-green-600 text-xl" />
                  <div>
                    <span className="font-semibold">Dúvidas?</span>
                    <p className="text-sm text-gray-600">Entre em contato via nosso WhatsApp</p>
                  </div>
                </div>
{ /* Botão de Contato via WhatsApp */ }
<a
  href="https://wa.me/559591273913?text=Olá,%20estou%20interessado%20em%20saber%20mais%20sobre%20um%20produto%20disponível%20no%20site%20ecoseyewear.com.br.%20Poderia%20me%20fornecer%20mais%20informações,%20por%20favor?"
  target="_blank"
  rel="noopener noreferrer"
  className="mt-4 inline-flex items-center bg-green-500 hover:bg-green-600 text-white px-4 py-2 rounded-md transition-colors duration-300 mr-4"
>
  <FaWhatsapp className="mr-2" />
  Fale Conosco
</a>

{ /* Botão de Instagram */ }
<a
  href="https://www.instagram.com/ecos.eyewear"
  target="_blank"
  rel="noopener noreferrer"
  className="mt-4 inline-flex items-center bg-pink-500 hover:bg-pink-600 text-white px-4 py-2 rounded-md transition-colors duration-300"
>
  <FaInstagram className="mr-2" />
  Instagram
</a>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Seção de Descrição do Produto */}
      <ProductDetailsSectionTwo description={sProduct.pDescription} />
    </Fragment>
  );
};

export default ProductDetailsSection;
