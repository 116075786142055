import React, { useState } from "react";
import Navbar from "../../partials/Navber"; // Deixe "Navber"
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const OrderTracker = () => {
  const [trackingCode, setTrackingCode] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (trackingCode.trim()) {
      setSubmitted(true);
      setTrackingCode("");
    }
  };

  return (
    <>
      <Navbar />
      <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100 px-4">
        <div className="bg-white shadow-lg rounded-lg p-8 w-full max-w-md">
          <h1 className="text-3xl font-bold text-gray-800 text-center mb-6">
            Rastrear Pedido
          </h1>
          <p className="text-gray-600 text-center mb-6">
            Insira o código de rastreamento abaixo para verificar o status do seu pedido. Nossa equipe entrará em contato com você em até 10 minutos após a solicitação.
          </p>
          <form onSubmit={handleSubmit} className="space-y-4">
            <input
              type="text"
              placeholder="Digite o código de rastreamento"
              value={trackingCode}
              onChange={(e) => setTrackingCode(e.target.value)}
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
            />
            <button
              type="submit"
              className="w-full bg-gray-600 text-white font-semibold py-2 rounded-md hover:bg-gray-700 transition duration-200"
            >
              Verificar Status
            </button>
          </form>

          {submitted && (
            <div className="mt-6 bg-blue-100 border border-blue-300 text-blue-700 px-4 py-3 rounded-md text-center">
              Em até 10 minutos, nossa equipe entrará em contato com você para
              fornecer informações detalhadas sobre o status do seu pedido.
            </div>
          )}
        </div>
      </div>
      <Footer />
      <CartModal />
    </>
  );
};

export default OrderTracker;
