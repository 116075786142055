import React, { Fragment, useContext } from "react";
import ProductCategoryDropdown from "./ProductCategoryDropdown";
import { HomeContext } from "./index";

const ProductCategory = () => {
  const { data, dispatch } = useContext(HomeContext);

  const toggleDropdown = (type) => {
    dispatch({ type, payload: !data[type] });
  };

  return (
    <Fragment>
      <div className="flex justify-between items-center font-normal border-b pb-3 mb-4">
        <div
          role="button"
          aria-label="Toggle Category List"
          onClick={() => toggleDropdown("categoryListDropdown")}
          className={`flex items-center space-x-1 cursor-pointer transition-all duration-300 ${
            data.categoryListDropdown ? "text-yellow-800 font-medium" : "text-gray-800"
          }`}
        >
          <span className="text-md md:text-lg hover:text-yellow-800">Categorias</span>
          <svg
            className={`w-4 h-4 transform transition-transform duration-300 ${
              data.categoryListDropdown ? "rotate-180" : "rotate-0"
            }`}
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="1.5"
              d="M19 9l-7 7-7-7"
            ></path>
          </svg>
        </div>
        <div className="flex space-x-4">
          {/* Botão Filtros */}
          <div
            role="button"
            aria-label="Toggle Filtros List"
            onClick={() => toggleDropdown("filterListDropdown")}
            className={`flex items-center space-x-1 cursor-pointer transition-all duration-300 ${
              data.filterListDropdown ? "text-yellow-800 font-medium" : "text-gray-800"
            }`}
          >
            <span className="text-md md:text-lg hover:text-yellow-800">Filtros</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Ícone de filtro */}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M3 4a1 1 0 011-1h16a1 1 0 011 1v2a1 1 0 01-.293.707l-6.707 6.707a1 1 0 00-.293.707v5.586a1 1 0 01-.293.707l-2 2A1 1 0 0110 21v-5.586a1 1 0 00-.293-.707L3 6.707A1 1 0 013 6V4z"
              ></path>
            </svg>
          </div>
          {/* Botão Pesquisa */}
          <div
            role="button"
            aria-label="Toggle Pesquisa List"
            onClick={() => toggleDropdown("searchDropdown")}
            className={`flex items-center space-x-1 cursor-pointer transition-all duration-300 ${
              data.searchDropdown ? "text-yellow-800 font-medium" : "text-gray-800"
            }`}
          >
            <span className="text-md md:text-lg hover:text-yellow-800">Pesquisa</span>
            <svg
              className="w-4 h-4"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              {/* Ícone de pesquisa */}
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.5"
                d="M21 21l-4.35-4.35m0 0A7 7 0 1117 10.65l4.35 4.35z"
              ></path>
            </svg>
          </div>
        </div>
      </div>
      <ProductCategoryDropdown />
    </Fragment>
  );
};

export default ProductCategory;
