import React, { useReducer } from "react";
import { useHistory } from "react-router-dom";
import AdminLayout from "../../../components/shop/layout";
import { pay } from "./Mixins";
import {
  layoutState,
  layoutReducer,
} from "../../../components/shop/layout/layoutContext"; // Atualize o caminho conforme necessário

const getContainerClassName = (collectionStatus) => {
  switch (collectionStatus) {
    case "approved":
      return "aprovado";
    case "pending":
      return "pendente";
    default:
      return "rejeitado";
  }
};

const DetailPayment = ({ location }) => {
  const [, dispatch] = useReducer(layoutReducer, layoutState);
  const history = useHistory();

  const handlePayment = () => {
    pay(dispatch); // Passe dispatch para pay
    localStorage.setItem("cart", JSON.stringify([]));

    // Atualiza o estado do pedido para sucesso
    dispatch({ type: "orderSuccess", payload: true });

    // Redireciona para a página inicial
    history.push("/");
  };

  const params = new URLSearchParams(location.search);
  const collectionStatus = params.get("collection_status");

  console.log(collectionStatus);

  const containerClassName = `containerresultadocompra ${getContainerClassName(
    collectionStatus
  )}`;

  return (
    <AdminLayout>
      <div
        className={`${
          containerClassName ? "" : "hidden"
        } fixed bottom-0 flex justify-between items-center z-30 w-full bg-green-800 text-white text-lg py-8 md:py-16 md:text-xl px-4 text-center`}>
        <span className="w-10/12 md:w-full">
          {collectionStatus === "approved"
            ? "Seu pedido foi confirmado"
            : collectionStatus === "pending"
            ? "Compra Pendente"
            : "Erro ao Pagar"}
        </span>
        <button
          className="hover:bg-gray-400 hover:text-gray-800 p-2 rounded-full cursor-pointer"
          onClick={handlePayment}>
          <svg
            className="w-6 h-6"
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg">
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 111.414 1.414L11.414 10l4.293 4.293a1 1 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            />
          </svg>
        </button>
      </div>
    </AdminLayout>
  );
};

export default DetailPayment;
