import React, { useEffect } from "react";

const FORM_ID = "payment-form";

export default function Product({ id }) {
  useEffect(() => {
    if (id) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src =
        "https://www.mercadopago.com.br/integrations/v1/web-payment-checkout.js";
      script.setAttribute("data-preference-id", id);
      const form = document.getElementById(FORM_ID);

      while (form.firstChild) {
        form.removeChild(form.firstChild);
      }

      form.appendChild(script);

      return () => {
        if (form) {
          while (form.firstChild) {
            form.removeChild(form.firstChild);
          }
        }
      };
    }
  }, [id]);

  return id ? <form id={FORM_ID} method="GET"></form> : <div></div>;
}
