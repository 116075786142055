import React, { useEffect, useContext } from "react";
import Slider from "react-slick";
import { HomeContext } from "./";
import { sliderImages } from "../../admin/dashboardAdmin/Action";

const apiURL = process.env.REACT_APP_API_URL;

const CustomSlider = () => {
  const { data, dispatch } = useContext(HomeContext);

  useEffect(() => {
    sliderImages(dispatch);
  }, [dispatch]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  return (
    <div className="relative mt-16 bg-gray-100 border-2 h-[300px] sm:h-[400px] md:h-[500px] lg:h-[600px]">
      {/* Ajuste a altura para diferentes tamanhos de tela */}
      <Slider {...settings}>
        {data.sliderImages.map((image, index) => (
          <div key={index} className="h-full flex justify-center items-center">
            <img
              className="w-full h-full object-cover" // Usa object-cover para manter a proporção
              src={`${apiURL}/uploads/customize/${image.slideImage}`}
              alt="sliderImage"
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;