import React from "react";
import Navbar from "../../partials/Navber";
import Footer from "../../partials/Footer";
import CartModal from "../../partials/CartModal";

const SobreNos = () => {
  return (
    <>
      <Navbar />
      <div className="container mx-auto mt-24 px-4 md:px-12">
        <div className="flex flex-col md:flex-row">
          {/* Sidebar */}
          <nav className="w-full md:w-1/4 mb-8 md:mb-0 md:mr-8">
            <ul className="space-y-4">
              <li>
                <a
                  href="#nossa-historia"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Nossa História
                </a>
              </li>
              <li>
                <a
                  href="#missao-e-visao"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Missão e Visão
                </a>
              </li>
              <li>
                <a
                  href="#compromisso"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Compromisso com o Cliente
                </a>
              </li>
              <li>
                <a
                  href="#diferenciais"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Diferenciais da Marca
                </a>
              </li>
              <li>
                <a
                  href="#nossos_produtos"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Nossos Produtos
                </a>
              </li>
              <li>
                <a
                  href="#contato"
                  className="text-lg font-semibold text-gray-800 hover:text-gray-600"
                >
                  Contato
                </a>
              </li>
            </ul>
          </nav>

          {/* Main Content */}
          <div className="w-full md:w-3/4 md:pl-8">
            <section id="nossa-historia" className="mb-16">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Nossa História
              </h2>
              <p className="text-lg text-gray-700">
                Na Ecos Eyewear, acreditamos que os óculos são mais do que
                apenas um acessório — eles são uma expressão de estilo e
                personalidade. Nossa loja online foi criada com o objetivo de
                oferecer uma experiência de compra prática e acessível para
                todos que buscam óculos solares e armações de qualidade. Desde o
                início, nossa missão foi simplificar a forma como você adquire
                seus óculos, trazendo conforto e praticidade diretamente para a
                sua casa.
              </p>
            </section>

            <section id="missao-e-visao" className="mb-16">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Missão e Visão
              </h2>
              <p className="text-lg text-gray-700">
                Nosso compromisso é fornecer produtos de alta qualidade que não
                apenas atendam às suas necessidades visuais, mas que também
                reflitam seu estilo único. Na Ecos Eyewear, estamos sempre
                buscando maneiras de inovar e melhorar, garantindo que cada
                cliente tenha uma experiência de compra excepcional.
              </p>
            </section>

            <section id="compromisso" className="mb-16">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Compromisso com o Cliente
              </h2>
              <p className="text-lg text-gray-700">
                Estamos dedicados a oferecer um atendimento ao cliente de
                primeira linha, pois sabemos que cada detalhe importa. Desde a
                seleção cuidadosa dos nossos produtos até o suporte pós-compra,
                cada aspecto da nossa loja foi pensado para que você se sinta
                valorizado e satisfeito.
              </p>
            </section>

            <section id="diferenciais" className="mb-16">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Diferenciais da Marca
              </h2>
              <p className="text-lg text-gray-700">
                O que torna a Ecos Eyewear única é o nosso foco inabalável na
                qualidade. Trabalhamos com fornecedores confiáveis e utilizamos
                materiais de primeira linha, garantindo que nossos óculos sejam
                duráveis e estilosos. Nossa coleção inclui uma variedade de
                estilos, desde os clássicos atemporais até as últimas
                tendências, tudo a um clique de distância.
              </p>
            </section>

            <section id="nossos_produtos" className="mb-16">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">
                Nossos Produtos
              </h2>
              <p className="text-lg text-gray-700">
                Oferecemos uma seleção diversificada de óculos solares e
                armações, sempre atualizada com as melhores opções do mercado.
                Cada peça é escolhida com o máximo cuidado para garantir que
                você encontre exatamente o que procura.
              </p>
            </section>

            <section id="contato">
              <h2 className="text-3xl font-bold text-gray-800 mb-4">Contato</h2>
              <p className="text-lg text-gray-700">
                Estamos sempre à disposição para ajudar. Se você tiver qualquer
                dúvida ou precisar de assistência, entre em contato conosco
                através do nosso WhatsApp, e-mail ou redes sociais. Nossa equipe
                está pronta para oferecer o suporte necessário e garantir que
                você tenha a melhor experiência de compra.
              </p>
              <p className="text-lg text-gray-700 mt-4">
                Na Ecos Eyewear, sua satisfação é a nossa prioridade. Venha
                descobrir a facilidade e a praticidade de comprar óculos online,
                e sinta a diferença que nossos produtos podem fazer no seu dia a
                dia. Junte-se a nós e experimente o mundo através dos Óculos da
                Ecos Eyewear!
              </p>
            </section>
          </div>
        </div>
      </div>
      <div className="mt-16">
        <Footer />
      </div>
      <CartModal />
    </>
  );
};

export default SobreNos;
