import React, { Fragment, useState, useContext, useEffect } from "react";
import Layout from "./Layout";
import { handleChangePassword } from "./Action";
import { DashboardUserContext } from "./Layout";

import zxcvbn from "zxcvbn"; // Biblioteca para avaliação da força da senha

const SettingComponent = () => {
  const { data, dispatch } = useContext(DashboardUserContext);

  const [fData, setFdata] = useState({
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
    success: "",
    error: "",
    passwordView: {
      oldPassword: false,
      newPassword: false,
      confirmPassword: false,
    },
  });

  const [passwordStrength, setPasswordStrength] = useState(null);

  // Função para alternar a visibilidade da senha
  const togglePasswordView = (field) => {
    setFdata((prevData) => ({
      ...prevData,
      passwordView: {
        ...prevData.passwordView,
        [field]: !prevData.passwordView[field],
      },
    }));
  };

  // Função para avaliar a força da nova senha
  useEffect(() => {
    if (fData.newPassword) {
      const evaluation = zxcvbn(fData.newPassword);
      setPasswordStrength(evaluation.score);
    } else {
      setPasswordStrength(null);
    }
  }, [fData.newPassword]);

  // Função para limpar mensagens após exibição
  useEffect(() => {
    if (fData.success || fData.error) {
      const timer = setTimeout(() => {
        setFdata((prevData) => ({ ...prevData, success: "", error: "" }));
      }, 3000); // Aumentei para 3 segundos para melhor visibilidade

      return () => clearTimeout(timer);
    }
  }, [fData.success, fData.error]);

  // Função de validação do formulário
  const validateForm = () => {
    const { oldPassword, newPassword, confirmPassword } = fData;
    if (!oldPassword || !newPassword || !confirmPassword) {
      setFdata((prevData) => ({
        ...prevData,
        error: "Todos os campos são obrigatórios.",
      }));
      return false;
    }

    if (newPassword !== confirmPassword) {
      setFdata((prevData) => ({
        ...prevData,
        error: "A nova senha e a confirmação não coincidem.",
      }));
      return false;
    }

    // Verificar força da senha mínima (score >= 2)
    if (passwordStrength !== null && passwordStrength < 2) {
      setFdata((prevData) => ({
        ...prevData,
        error: "A nova senha é muito fraca. Por favor, escolha uma senha mais forte.",
      }));
      return false;
    }

    return true;
  };

  // Função para lidar com a submissão do formulário
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    const response = await handleChangePassword(dispatch, {
      oldPassword: fData.oldPassword,
      newPassword: fData.newPassword,
    });

    if (response.success) {
      setFdata({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        success: "Senha alterada com sucesso!",
        error: "",
        passwordView: {
          oldPassword: false,
          newPassword: false,
          confirmPassword: false,
        },
      });
      setPasswordStrength(null);
    } else {
      setFdata((prevData) => ({
        ...prevData,
        error: response.message || "Erro ao alterar a senha.",
        success: "",
      }));
    }
  };

  if (data.loading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
          ></path>
        </svg>
      </div>
    );
  }

  // Função para renderizar a barra de força da senha
  const renderPasswordStrength = () => {
    const strengthLabels = ["Fraca", "Moderada", "Boa", "Forte", "Muito Forte"];
    const strengthColors = ["bg-red-500", "bg-yellow-500", "bg-blue-500", "bg-green-500", "bg-purple-500"];

    if (passwordStrength === null) return null;

    return (
      <div className="mt-1">
        <div className="flex items-center">
          <div className="w-full bg-gray-300 rounded h-2 mr-2">
            <div
              className={`h-2 rounded ${strengthColors[passwordStrength]}`}
              style={{ width: `${(passwordStrength + 1) * 20}%` }}
            ></div>
          </div>
          <span className="text-sm text-gray-700">{strengthLabels[passwordStrength]}</span>
        </div>
      </div>
    );
  };

  return (
    <Fragment>
      <div className="flex flex-col w-full my-4 md:my-0 md:w-9/12 md:px-8">
        <div className="shadow-lg border">
          <div className="py-4 px-4 text-lg font-semibold border-t-2 border-yellow-700">
            Alterar Senha
          </div>
          <hr />
          <form onSubmit={handleSubmit}>
            <div className="py-4 px-4 md:px-8 lg:px-16 flex flex-col space-y-4">
              {fData.success && (
                <div className="bg-green-200 px-4 py-2 rounded text-green-800">
                  {fData.success}
                </div>
              )}
              {fData.error && (
                <div className="bg-red-200 px-4 py-2 rounded text-red-800">
                  {fData.error}
                </div>
              )}
              {/* Senha Atual */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="oldPassword">Senha Atual</label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setFdata({ ...fData, oldPassword: e.target.value })
                    }
                    value={fData.oldPassword}
                    type={fData.passwordView.oldPassword ? "text" : "password"}
                    id="oldPassword"
                    className="z-10 border px-4 py-2 w-full focus:outline-none"
                    placeholder="Digite sua senha atual"
                    required
                  />
                  <span
                    onClick={() => togglePasswordView("oldPassword")}
                    className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer text-gray-600"
                  >
                    {fData.passwordView.oldPassword ? (
                      // Ícone para esconder a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    ) : (
                      // Ícone para mostrar a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>

              {/* Nova Senha */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="newPassword">Nova Senha</label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setFdata({ ...fData, newPassword: e.target.value })
                    }
                    value={fData.newPassword}
                    type={fData.passwordView.newPassword ? "text" : "password"}
                    id="newPassword"
                    className="z-10 border px-4 py-2 w-full focus:outline-none"
                    placeholder="Digite sua nova senha"
                    required
                  />
                  <span
                    onClick={() => togglePasswordView("newPassword")}
                    className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer text-gray-600"
                  >
                    {fData.passwordView.newPassword ? (
                      // Ícone para esconder a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    ) : (
                      // Ícone para mostrar a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>
                {renderPasswordStrength()}
              </div>

              {/* Confirmar Nova Senha */}
              <div className="flex flex-col space-y-2">
                <label htmlFor="confirmPassword">Confirme a Nova Senha</label>
                <div className="relative">
                  <input
                    onChange={(e) =>
                      setFdata({ ...fData, confirmPassword: e.target.value })
                    }
                    value={fData.confirmPassword}
                    type={fData.passwordView.confirmPassword ? "text" : "password"}
                    id="confirmPassword"
                    className="z-10 border px-4 py-2 w-full focus:outline-none"
                    placeholder="Confirme sua nova senha"
                    required
                  />
                  <span
                    onClick={() => togglePasswordView("confirmPassword")}
                    className="absolute right-0 top-0 mt-3 mr-4 cursor-pointer text-gray-600"
                  >
                    {fData.passwordView.confirmPassword ? (
                      // Ícone para esconder a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    ) : (
                      // Ícone para mostrar a senha
                      <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"
                        />
                      </svg>
                    )}
                  </span>
                </div>
              </div>

              {/* Botão de Submissão */}
              <button
                type="submit"
                className="w-full text-center bg-gray-800 text-gray-100 px-4 py-2 rounded hover:bg-gray-700 transition-colors duration-200"
              >
                Alterar Senha
              </button>
            </div>
          </form>
        </div>
      </div>
    </Fragment>
  );
};

const SettingUser = () => {
  return <Layout children={<SettingComponent />} />;
};

export default SettingUser;
