import React, { Fragment, useContext, useEffect, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { LayoutContext } from "../index";
import { cartListProduct } from "./FetchApi";
import { isAuthenticate } from "../auth/fetchApi";
import { cartList, subTotal, quantity, totalCost } from "./Mixins";

const apiURL = process.env.REACT_APP_API_URL;

const CartModal = () => {
  const history = useHistory();
  const { data, dispatch } = useContext(LayoutContext);
  const products = data.cartProduct;

  const fetchData = useCallback(async () => {
    try {
      const responseData = await cartListProduct();
      if (responseData?.Products) {
        dispatch({ type: "cartProduct", payload: responseData.Products });
        dispatch({ type: "cartTotalCost", payload: totalCost() });
      }
    } catch (error) {
      console.error("Erro ao buscar dados do carrinho:", error);
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const cartModalOpen = () =>
    dispatch({ type: "cartModalToggle", payload: !data.cartModal });

  const removeCartProduct = (id) => {
    let cart = JSON.parse(localStorage.getItem("cart")) || [];
    cart = cart.filter((item) => item.id !== id);
    localStorage.setItem("cart", JSON.stringify(cart));
    fetchData();
    dispatch({ type: "inCart", payload: cartList() });
    dispatch({ type: "cartTotalCost", payload: totalCost() });

    if (cart.length === 0) {
      dispatch({ type: "cartProduct", payload: null });
    }
  };

  const handleCheckoutClick = () => {
    history.push("/checkout");
    cartModalOpen();
  };

  const handleLoginRedirect = () => {
    history.push("/");
    cartModalOpen();
    dispatch({ type: "loginSignupError", payload: !data.loginSignupError });
    dispatch({
      type: "loginSignupModalToggle",
      payload: !data.loginSignupModal,
    });
  };

  return (
    <>
      {/* Overlay Preto */}
      <div
        className={`${
          !data.cartModal ? "hidden" : ""
        } fixed top-0 z-30 w-full h-full bg-gray-800 opacity-50`}
      />
      {/* Modal do Carrinho */}
      <section
        className={`${
          !data.cartModal ? "hidden" : ""
        } fixed z-40 inset-0 flex items-center justify-end`}>
        <div className="w-full md:w-5/12 lg:w-4/12 h-full flex flex-col justify-between bg-white shadow-lg">
          {/* Cabeçalho do Carrinho */}
          <div className="overflow-y-auto">
            <div className="border-b border-gray-300 flex justify-between items-center p-4">
              <div className="text-gray-800 text-lg font-semibold">
                Carrinho Ecos Eyewaer
              </div>
              <button onClick={cartModalOpen} className="text-gray-800">
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg">
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
            </div>
            {/* Lista de Produtos no Carrinho */}
            <div className="m-4">
              {products && products.length > 0 ? (
                products.map((item, index) => (
                  <Fragment key={index}>
                    {/* Produto no Carrinho */}
                    <div className="flex items-center space-x-4 my-4">
                      <img
                        className="w-16 h-16 object-cover rounded-md"
                        src={`${apiURL}/uploads/products/${item.pImages[0]}`}
                        alt={item.pName}
                      />
                      <div className="flex-grow relative">
                        <div className="font-medium text-gray-800">{item.pName}</div>
                        <div className="flex items-center justify-between mt-2">
                          <div className="text-sm text-gray-600">
                            Quantidade: {quantity(item._id)}
                          </div>
                          <div className="text-sm text-gray-600">
                            SubTotal: R$ {subTotal(item._id, item.pPrice).toFixed(2)}
                          </div>
                        </div>
                        {/* Botão de Remover Produto */}
                        <button
                          onClick={() => removeCartProduct(item._id)}
                          className="absolute top-0 right-0 text-gray-600 hover:text-red-500">
                          <svg
                            className="w-5 h-5"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M6 18L18 6M6 6l12 12"></path>
                          </svg>
                        </button>
                      </div>
                    </div>
                  </Fragment>
                ))
              ) : (
                <div className="text-center text-gray-600 text-lg">
                  Nenhum produto no carrinho
                </div>
              )}
            </div>
          </div>
          {/* Rodapé do Carrinho */}
          <div className="m-4 space-y-4">
            <button
              onClick={cartModalOpen}
              className="w-full px-4 py-2 border border-gray-400 text-gray-800 rounded-full hover:bg-gray-100 transition duration-300">
              Continuar comprando
            </button>
            {data.cartTotalCost ? (
              isAuthenticate() ? (
                <button
                  onClick={handleCheckoutClick}
                  className="w-full px-4 py-2 bg-green-600 text-white font-semibold rounded-full hover:bg-green-700 transition duration-300">
                  Pagar R$ {data.cartTotalCost.toFixed(2)}
                </button>
              ) : (
                <button
                  onClick={handleLoginRedirect}
                  className="w-full px-4 py-2 bg-green-600 text-white font-semibold rounded-full hover:bg-green-700 transition duration-300">
                  Pagar R$ {data.cartTotalCost.toFixed(2)}
                </button>
              )
            ) : (
              <button
                className="w-full px-4 py-2 bg-green-600 text-white font-semibold rounded-full hover:bg-green-700 transition duration-300">
                Pagar R$ 0,00
              </button>
            )}
          </div>
        </div>
      </section>
      {/* Fim do Modal do Carrinho */}
    </>
  );
};

export default CartModal;
