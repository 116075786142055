import React, { Fragment, useEffect, useContext, useCallback, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { getAllProduct } from "../../admin/products/FetchApi";
import { HomeContext } from "./index";
import { isWishReq, unWishReq, isWish } from "./Mixins";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./ProductCarousel.css";

const apiURL = process.env.REACT_APP_API_URL;

const ProductCarousel = () => {
  const { data, dispatch } = useContext(HomeContext);
  const { products, loading } = data;
  const history = useHistory();
  const [wList, setWlist] = useState(JSON.parse(localStorage.getItem("wishList")));

  const fetchData = useCallback(async () => {
    dispatch({ type: "loading", payload: true });
    try {
      const responseData = await getAllProduct();
      if (responseData?.Products) {
        dispatch({ type: "setProducts", payload: responseData.Products });
      }
    } catch (error) {
      console.error("Erro ao buscar produtos:", error);
    } finally {
      dispatch({ type: "loading", payload: false });
    }
  }, [dispatch]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 4,
    centerMode: true,
    autoplay: true,
    autoplaySpeed: 3000,
    swipeToSlide: true,
    slidesToScroll: 1,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 3 } },
      { breakpoint: 992, settings: { slidesToShow: 2 } },
      { breakpoint: 768, settings: { slidesToShow: 1, arrows: false, centerMode: true } },
    ],
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center py-24">
        <LoadingSpinner />
      </div>
    );
  }

  return (
    <Fragment>
      <Slider {...settings} className="w-full px-2 md:px-4 lg:px-6">
        {products && products.length > 0 ? (
          products.map((item) => (
            <ProductCard
              key={item._id}
              item={item}
              history={history}
              wList={wList}
              setWlist={setWlist}
            />
          ))
        ) : (
          <div className="text-center py-24 text-2xl">Nenhum Produto Encontrado</div>
        )}
      </Slider>
    </Fragment>
  );
};

const LoadingSpinner = () => (
  <svg
    className="w-12 h-12 animate-spin text-gray-600"
    fill="none"
    stroke="currentColor"
    viewBox="0 0 24 24"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
    />
  </svg>
);

const ProductCard = ({ item, history, wList, setWlist }) => (
  <div className="p-2">
    <div className="bg-white overflow-hidden relative flex flex-col justify-between h-96 rounded-lg">
      <div className="relative h-80 w-full flex items-center justify-center">
        <img
          onClick={() => history.push(`/products/${item._id}`)}
          src={`${apiURL}/uploads/products/${item.pImages[0]}`}
          alt={item.pName}
          className="w-full h-full object-contain cursor-pointer rounded-t-lg"
        />
      </div>
      <div className="p-4">
        <h2 className="text-gray-900 text-base font-semibold truncate mb-2">{item.pName}</h2>
        <ProductPrice item={item} />
      </div>
      <button
        onClick={() => history.push(`/products/${item._id}`)}
        className="w-full bg-green-600 text-white text-center py-2 font-medium hover:bg-green-700 transition-all duration-300 mt-4 rounded-b-lg"
      >
        Comprar agora
      </button>
      <WishListIcons item={item} wList={wList} setWlist={setWlist} />
    </div>
  </div>
);

const ProductPrice = ({ item }) => (
  <div>
    <div className="flex items-center">
      <div className="text-green-700 text-lg font-bold">R${item.pPrice}</div>
      {item.pOffer && (
        <span className="text-gray-500 text-sm line-through ml-2">R${item.pOffer}</span>
      )}
    </div>
    <div className="text-gray-600 text-sm mt-1 flex items-center">
      <FontAwesomeIcon icon={faCreditCard} className="mr-1" />
      3x de R${(item.pPrice / 3).toFixed(2)} sem juros
    </div>
  </div>
);

const WishListIcons = ({ item, wList, setWlist }) => (
  <div className="absolute top-0 right-0 mx-2 my-2 flex items-center space-x-2">
    <svg
      onClick={(e) => isWishReq(e, item._id, setWlist)}
      className={`${isWish(item._id, wList) ? "hidden" : ""} w-5 h-5 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        d="M4.318 6.318a4.5 4.5 0 000 6.364L12 20.364l7.682-7.682a4.5 4.5 0 00-6.364-6.364L12 7.636l-1.318-1.318a4.5 4.5 0 00-6.364 0z"
      />
    </svg>
    <svg
      onClick={(e) => unWishReq(e, item._id, setWlist)}
      className={`${!isWish(item._id, wList) ? "hidden" : ""} w-5 h-5 cursor-pointer text-red-800 transition-all duration-300 ease-in`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        d="M3.172 5.172a4 4 0 015.656 0L10 6.343l1.172-1.171a4 4 0 115.656 5.656L10 17.657l-6.828-6.829a4 4 0 010-5.656z"
        clipRule="evenodd"
      />
    </svg>
  </div>
);

export default ProductCarousel;
