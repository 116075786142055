import React, { Fragment, useEffect, useContext } from "react";
import moment from "moment";
import { fetchOrderByUser } from "./Action";
import Layout, { DashboardUserContext } from "./Layout";
import { FaWhatsapp } from "react-icons/fa"; // Importando o ícone do WhatsApp

const apiURL = process.env.REACT_APP_API_URL;

// Componente para o Cabeçalho da Tabela
const TableHeader = () => {
  return (
    <thead>
      <tr>
        <th className="px-4 py-2 border">Produtos</th>
        <th className="px-4 py-2 border">Status do Pedido</th>
        <th className="px-4 py-2 border">Valor Total</th>
        <th className="px-4 py-2 border">Código da Rastreio</th>
        <th className="px-4 py-2 border">Data da Solicitação</th>
      </tr>
    </thead>
  );
};

// Componente para o Corpo da Tabela
const TableBody = ({ order }) => {
  const statusColors = {
    "Não processado": "text-red-600",
    "Em Análise": "text-yellow-700",
    "Enviado": "text-yellow-700",
    "Entregue": "text-green-600",
    "Cancelado": "text-red-600",
  };

  return (
    <tr className="border-b">
      <td className="w-48 hover:bg-gray-200 p-2 flex flex-col space-y-1">
        {order.allProduct && order.allProduct.length > 0 ? (
          order.allProduct.map((product, index) => (
            <span className="block flex items-center space-x-2" key={product._id || index}>
              <img
                className="w-8 h-8 object-cover"
                src={`${apiURL}/uploads/products/${product.pImages && product.pImages[0]}`}
                alt={`Imagem de ${product.pName}`}
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = "/default-product.png"; // Imagem padrão caso a imagem não carregue
                }}
              />
              <span>{product.pName}</span>
              <span>{product.quantity}x</span>
            </span>
          ))
        ) : (
          <span className="text-gray-500">Nenhum produto encontrado.</span>
        )}
      </td>
      <td className="hover:bg-gray-200 p-2 text-center">
        <span className={`block ${statusColors[order.status] || "text-gray-600"} rounded-full text-xs px-2 font-semibold`}>
          {order.status}
        </span>
      </td>
      <td className="hover:bg-gray-200 p-2 text-center">
        R$ {order.amount ? order.amount.toFixed(2).replace(".", ",") : "0,00"}
      </td>
      <td className="hover:bg-gray-200 p-2 text-center">
        {order.transactionId || "N/A"}
      </td>
      <td className="hover:bg-gray-200 p-2 text-center">
        {order.updatedAt ? moment(order.updatedAt).format("DD/MM/YYYY") : "N/A"}
      </td>
    </tr>
  );
};

// Componente Principal para Exibir os Pedidos
const OrdersComponent = () => {
  const { data, dispatch } = useContext(DashboardUserContext);
  const orders = data.OrderByUser || [];

  useEffect(() => {
    fetchOrderByUser(dispatch);
  }, [dispatch]);

  if (data.loading) {
    return (
      <div className="w-full md:w-9/12 flex items-center justify-center py-24">
        <svg
          className="w-12 h-12 animate-spin text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
        </svg>
      </div>
    );
  }

  return (
    <Fragment>
      <div className="flex flex-col w-full my-4 md:my-0 md:w-9/12 md:px-8">
        <div className="shadow-lg border">
          <div className="py-4 px-4 text-lg font-semibold border-t-2 border-yellow-900">
            Histórico de Pedidos
          </div>
          <div className="overflow-auto bg-white shadow-lg p-4">
            <table className="table-auto border w-full my-2">
              <TableHeader />
              <tbody>
                {orders && orders.length > 0 ? (
                  orders.map((order) => <TableBody key={order._id} order={order} />)
                ) : (
                  <tr>
                    <td
                      colSpan="5"
                      className="text-xl text-center font-semibold py-8">
                      Nenhum pedido encontrado no momento.
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="text-sm text-gray-600 mt-2">
              Total de pedidos encontrados: {orders.length}.
            </div>

            {/* Nova Seção: Prazo de Entrega e Formas de Pagamento */}
            <div className="mt-6 p-4 bg-blue-50 border-l-4 border-blue-500">
              <h2 className="text-lg font-semibold text-blue-700">Prazo de Entrega</h2>
              <p className="mt-2 text-gray-700">
                <strong>Prazo de Entrega:</strong> Os pedidos são entregues em até <strong>7 a 10 dias úteis</strong> após a confirmação do pagamento.
              </p>
              
              <p className="mt-2 text-gray-700">
                <strong>Urgência:</strong> Em caso de urgência, entre em contato conosco através do WhatsApp ou e-mail para assistência imediata.
              </p>
            </div>

            {/* Seção de Contato */}
            <div className="mt-6 text-center">
              <span className="text-gray-600">
                Entre em contato com a Ecos Eyewear para saber mais!
              </span>
              <br />
              <a
                href="mailto:ecoseyewear@gmail.com"
                className="text-blue-500 underline">
                ecoseyewear@gmail.com
              </a>
              {/* Botão Discreto de WhatsApp */}
              <div className="mt-4">
                <a
                  href="https://wa.me/559591273913?text=Ol%C3%A1%2C%20estou%20acessando%20a%20sess%C3%A3o%20de%20meus%20pedidos%20no%20site%20ecoseyewear.com.br%20e%20gostaria%20de%20obter%20mais%20informa%C3%A7%C3%B5es%20sobre%20um%20pedido%20espec%C3%ADfico."
                  target="_blank"
                  rel="noopener noreferrer"
                  className="inline-flex items-center px-3 py-2 bg-green-500 text-white rounded-full shadow-md hover:bg-green-600 transition-colors duration-200"
                  aria-label="Contato via WhatsApp"
                >
                  <FaWhatsapp className="mr-2" />
                  WhatsApp
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

// Componente que Envolve o OrdersComponent no Layout
const UserOrders = () => {
  return <Layout children={<OrdersComponent />} />;
};

export default UserOrders;
